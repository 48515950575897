import { Button, List, ListItem, Preloader } from "framework7-react";
import React, { useEffect } from "react";
import LazyLoaderWraper from "../../../../components/misc/lazyloaderWraper";
import userimage from "../../../../assets/images/icons/no-user.svg";
import { useDispatch } from "react-redux";
import { acceptRejectRequestsService } from "../../../../redux/features/userSlice/acceptRejectRequest";
import { useTranslation } from "react-i18next";
import Nofriendrequest from "../../../../assets/images/icons/Nofriendrequest.svg";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../../../js/db";
import {  useSelector } from "react-redux";

const FriendRequests = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const friendRequestsArr = useLiveQuery(() => db.friendRequests.toArray());
	const friendRequests = friendRequestsArr?.slice().sort((a, b) => b.request_id - a.request_id);
	const { acceptRejectRequestResponse } = useSelector(
		(state) => state.acceptRejectRequestSlice
	);

	useEffect(() => {}, [acceptRejectRequestResponse]);

	const acceptRequest = async (params) => {
		dispatch(acceptRejectRequestsService(params));
		db.friendRequests.delete(params.request_id);
	};

	return (
		<div>
			<List mediaList>
				<ul>
					{!friendRequests ? (
						<div className="flex flex-col pt-5 justify-center items-center h-100 w-100">
							<Preloader />
						</div>
					) : !friendRequests.length ? (
						<div className="gap-[2.5px] flex flex-col justify-center items-center mt-[100%] p-[30px]">
							<span className="w-[252px] h-[25px] gap-0 opacity-[0px] text-lg font-bold leading-[24.55px] text-center text-[#5D6980]">
								{t("No new requests")}
							</span>
							<div className="w-[252px] h-11 gap-0 opacity-[0px] text-base font-normal leading-[21.82px] text-center text-[#5D6980]">
								{t(
									"When people send you requests to connect they’ll appear here."
								)}
							</div>
							<img src={Nofriendrequest} className="w-[125px] h-[125px]" />
						</div>
					) : (
						friendRequests.map((request, index) => (
							<ListItem
								key={request.request_id.toString() + "_" + index}
								noChevron
								style={{
									borderTop:
										index !== 0 &&
										"1px solid var(--f7-searchbar-input-border-color)",
								}}
								className="cursor-pointer"
							>
								<b slot="title">{request.firstname}</b>
								<p
									slot="subtitle"
									className="text-[16px] font-[400] text-[--f7-body-text] whitespace-normal"
								>
									{t("has sent you a friend request")}
								</p>
								<div slot="media">
									<LazyLoaderWraper
										src={request.profile_image || userimage}
										placeholder={userimage}
										alt=""
										className="rounded-full w-[55px] h-[55px] object-cover"
									/>
								</div>
								<div
									slot="content-end"
									className="h-full flex flex-wrap items-center gap-[5px]"
								>
									<Button
										onClick={() =>
											acceptRequest({
												request_id: request.request_id,
												status: "accept",
											})
										}
										className="w-[65px] h-[30px] rounded-[50px] text-white bg-[--f7-md-primary] cursor-pointer text-[14px]"
									>
										{t("Accept")}
									</Button>
									<Button
										onClick={() =>
											acceptRequest({
												request_id: request.request_id,
												status: "decline",
											})
										}
										className="w-[65px] h-[30px] rounded-[50px] text-white bg-[--f7-theme-secondary-text] cursor-pointer text-[14px]"
									>
										{t("Decline")}
									</Button>
								</div>
							</ListItem>
						))
					)}
				</ul>
			</List>
		</div>
	);
};

export default FriendRequests;
