import {
	AccordionContent,
	AccordionToggle,
	Button,
	List,
	ListItem,
	Preloader,
	f7,
	useStore,
} from "framework7-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import LazyLoaderWraper from "../../../../components/misc/lazyloaderWraper";
import userimage from "../../../../assets/images/placeholders/user.png";
import "./style.css";
import MaterialIcon from "../../../../components/misc/materialIcon";
import Nomentionshere from "../../../../assets/images/icons/Nomentionshere.svg";
import { db } from "../../../../js/db";
import { useLiveQuery } from "dexie-react-hooks";

const Mentions = () => {
	const { t, i18n } = useTranslation();
	const { mentionsResponse, isLoading } = useSelector(
		(state) => state.mentionsSlice
	);
	const activeConversation = useStore("activeConversation");

	const goToMessage = async ({ chat_id, message_id, type }) => {
		const msg = await db.messages.get({ id: message_id });

		if (msg) {
			if (activeConversation === chat_id) {
				f7.emit("searchMessage", message_id);
			} else {
				f7.store.dispatch("setActiveConversation", chat_id);
				f7.store.dispatch("setActiveConversationType", type);
				f7.store.dispatch("setActiveMessage", message_id);
			}
		} else {
			f7.dialog.alert(t("Please view this message in your phone"));
			//need workin on this with the db deep
		}
	};

	const markAsRead = async (mentionId) => {
		await db.mentions.update(mentionId, { is_read: 1 });
	};

	return (
		<div>
			<List mediaList className="mentions-list">
				<ul>
					{isLoading ? (
						<div className="flex flex-col pt-5 justify-center items-center h-100 w-100">
							<Preloader />
						</div>
					) : !mentionsResponse?.data?.length ? (
						<div className="gap-[2.5px] flex flex-col justify-center items-center mt-[100%] p-[30px]">
							<span className="w-[252px] h-[25px] gap-0 opacity-[0px] text-lg font-bold leading-[24.55px] text-center text-[#5D6980]">
								{t("No new mentions")}
							</span>
							<div className=" w-[252px] h-66 gap-0 opacity-[0px] text-base font-normal leading-[21.82px] text-center text-[#5D6980]">
								{t(
									"When people mention you in group chats you are in, they’ll appear here."
								)}
							</div>
							<img src={Nomentionshere} className="w-[125px] h-[125px]" />
						</div>
					) : (
						mentionsResponse.data.map((mention, index) => (
							<ListItem
								key={mention.id + "_" + index}
								noChevron
								style={{
									borderTop:
										index !== 0 &&
										"1px solid var(--f7-searchbar-input-border-color)",
								}}
								className="cursor-pointer"
								onClick={async () => {
									await markAsRead(mention.id); // Mark mention as read
									f7.panel.close();
									goToMessage({
										chat_id: mention.chat_id,
										type: mention.chat_type,
										message_id: mention.message_id,
									});
								}}>
								<span
									slot="title"
									className="text-base font-semibold leading-[21px]">
									<span className="font-bold">{mention.user}</span>{" "}
									{t("mentioned you {{in_chat}}", {
										in_chat:
											mention.chat_type === "group"
												? ` in ${mention.group_name}`
												: "",
									})}
								</span>
								<AccordionContent
									slot="subtitle"
									className="text-[14px] font-[400] text-[--f7-body-text]">
									{mention.message}
								</AccordionContent>
								<p
									slot="footer"
									className="text-[12px] text-[--f7-theme-secondary-text]">
									{t(`{{time}}`, {
										time: mention.time_ago,
									})}
								</p>
								<div slot="media" className="relative">
									<>
										{mention.is_read === 0 && (
											<span className="absolute h-2.5 w-2.5 bg-[#E05047] rounded-full -left-2.5"></span>
										)}
										<LazyLoaderWraper
											src={mention.profile_image || userimage}
											placeholder={userimage}
											alt=""
											className="rounded-full w-[55px] h-[55px] object-cover"
										/>
									</>
								</div>
								<AccordionToggle slot="after">
									<Button
										className="p-0 h-[32px]"
										onClick={(e) => e.target.classList.toggle("rotate-180")}>
										<MaterialIcon
											icon="keyboard_arrow_down"
											size={24}
											color="#5D6980"
											className="text-[24px]"
										/>
									</Button>
								</AccordionToggle>
							</ListItem>
						))
					)}
				</ul>
			</List>
		</div>
	);
};

export default Mentions;
