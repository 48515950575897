import React, { useContext, useEffect, useRef, useState } from "react";
import {
	Button,
	Chip,
	Link,
	List,
	ListItem,
	NavLeft,
	NavTitle,
	Navbar,
	Page,
	Preloader,
	Searchbar,
	Subnavbar,
	f7,
	useStore,
} from "framework7-react";
import MaterialIcon from "../../../../components/misc/materialIcon";
import { db } from "../../../../js/db";
import { useSelector } from "react-redux";
import { useLiveQuery } from "dexie-react-hooks";
import user from "../../../../assets/images/placeholders/user.png";
import LazyLoaderWraper from "../../../../components/misc/lazyloaderWraper";
import "./style.css";
import { Send } from "iconsax-react";
import { SocketContext } from "../../../../socket";
import { ENDPOINTS } from "../../../../constants/socket";
import { useTranslation } from "react-i18next";
import notesicon from "../../../../assets/images/icons/notes-icon.svg";
import useLocalStorageState from "use-local-storage-state";

const MessageForward = ({ chat_id, hideUnread, opened }) => {
	chat_id = chat_id
		? chat_id !== "posh_ai"
			? parseInt(chat_id)
			: chat_id
		: null;
	const { t, i18n } = useTranslation();
	const activeConversation = useStore("activeConversation");
	if (activeConversation) {
		chat_id =
			activeConversation !== "null"
				? activeConversation !== "posh_ai"
					? parseInt(activeConversation)
					: activeConversation
				: activeConversation;
	}
	const [messageContext] = useLocalStorageState("messageContext");
	const selectedMessages = useStore("selectedMessages");
	const searchbar = useRef(null);
	const contactsList = useRef(null);
	const notFoundList = useRef(null);
	const { isLoading } = useSelector((state) => state.contactSlice);
	const [selectedItems, setSelectedItems] = useState([]);
	const { loginResponse } = useSelector((state) => state.loginSlice);

	useEffect(() => {
		// searchbar.current?.el && f7.searchbar.destroy(searchbar.current.el);
		setSelectedItems([]);
	}, [chat_id]);

	const myOwnChatId = loginResponse.data.chat_id
		? parseInt(loginResponse.data.chat_id)
		: 0;
	const chats = useLiveQuery(
		() =>
			db.chats
				.orderBy("unix_time")
				.filter((chat) => chat.chat_id != myOwnChatId && (chat.can_send === undefined || chat.can_send === true))
				.desc()
				.toArray()
	);
	const socket = useContext(SocketContext);

	const handleItemClick = (item) => {
		const isSelected = selectedItems.find(
			(chat) => chat.chat_id === item.chat_id
		);
		if (isSelected) {
			setSelectedItems(
				selectedItems.filter(
					(selectedItem) => selectedItem.chat_id !== item.chat_id
				)
			);
		} else {
			setSelectedItems([...selectedItems, item]);
		}
	};
	const handleItemClickRemove = (item) => {
		setSelectedItems(
			selectedItems.filter(
				(selectedItem) => selectedItem.chat_id !== item.chat_id
			)
		);
	};
	// const [vlData, setVlData] = useState({
	// 	items: [],
	// });
	// const renderExternal = (vl, newData) => {
	// 	setVlData({ ...newData });
	// };

	// const searchAll = (query, searchItems) => {
	// 	console.log("search", searchItems);
	// 	const found = [];
	// 	for (let i = 0; i < searchItems.length; i += 1) {
	// 		console.log("search", searchItems[i]);
	// 		if (
	// 			searchItems[i]?.name ||
	// 			searchItems[i]?.NAME ||
	// 			`User-${searchItems[i]?.id}`
	// 				.toLowerCase()
	// 				.indexOf(query.toLowerCase()) >= 0 ||
	// 			query.trim() === ""
	// 		)
	// 			found.push(i);
	// 	}
	// 	return found;
	// };

	useEffect(() => {
		if (chats?.length > 0) {
			// if (contactsList.current) {
			// 	f7.virtualList.create({
			// 		el: contactsList.current.el,
			// 		items: chats,
			// 		createUl: false,
			// 		renderExternal,
			// 		searchAll,
			// 		height: "auto",
			// 	});
			// }
			if (searchbar.current) {
				searchbar.current?.f7Searchbar &&
					searchbar.current.el &&
					f7.searchbar.destroy(searchbar.current.el);
				f7.searchbar.create({
					el: searchbar.current.el,
					searchContainer: ".contact-list3",
					searchIn: ".item-title",
					notFoundEl: notFoundList.current.el,
					backdrop: false,
					inner: false,
					disableButton: false,
					customSearch: false,
				});
			}
		}

		return () => {
			searchbar.current?.f7Searchbar &&
				searchbar.current.el &&
				f7.searchbar.destroy(searchbar.current.el);
			if (chats?.length > 0 && searchbar.current) {
				f7.searchbar.create({
					el: searchbar.current.el,
					searchContainer: ".contact-list3",
					searchIn: ".item-title",
					notFoundEl: notFoundList.current.el,
					backdrop: false,
					inner: false,
					disableButton: false,
					customSearch: false,
				});
			}
		};
	}, [chats, searchbar]);

	const forward = () => {
		if (!messageContext) return;
		const contextIds = messageContext
			? messageContext instanceof Array
				? messageContext?.map((obj) => obj.id)
				: [messageContext?.id]
			: selectedMessages.map((obj) => obj.id);
		const chatIds = selectedItems.map((obj) => ({
			chat_id: obj.chat_id || 0,
			receiver_id: obj.id || 0,
		}));

		//check with backend why array with multiple message id not working. for now send socket over a loop as fall back

		contextIds.map((message_id, index) => {
			socket.emit(
				ENDPOINTS.FORWARD_MESSAGE,
				JSON.stringify({
					message_id: [message_id],
					chats: chatIds,
				}),
				(response) => {
					if (!response.message_id && response.message) {
						f7.toast
							.create({
								text: response.message,
								position: "bottom",
								closeTimeout: 3000,
							})
							.open();
					}

					if (chat_id && chatIds.includes(chat_id)) {
						hideUnread();
					}
					if (index === contextIds.length - 1) {
						setSelectedItems([]);
						f7.panel.close();
					}
				}
			);
		});
	};

	return (
		<Page>
			<Navbar>
				<NavLeft>
					<Link
						panelClose
						onClick={() => {
							setSelectedItems([]);
						}}>
						<MaterialIcon size={48} icon="close" />
					</Link>
				</NavLeft>
				<NavTitle className="text-[--f7-heading-color] font-bold">
					{t("Forward to...")}
				</NavTitle>
			</Navbar>
			<Subnavbar>
				{chats?.length > 0 && (
					<Searchbar
						init={false}
						ref={searchbar}
						inline
						backdrop={false}
						disableButton={false}
						inner={false}
						placeholder={t("Type contact name")}
						className="w-full"
					/>
				)}
			</Subnavbar>

			<div className="flex px-5 gap-2 flex-wrap">
				{selectedItems?.map((item) => (
					<Chip
						key={item.chat_id}
						className="bg-[--f7-navbar-bg-color] pr-5 font-[400]  rounded-[100px] text-[12px] text-[--f7-heading-color] md:text-[14px]"
						text={`${
							item.chat_id === loginResponse.data.chat_id
								? t("My Notes")
								: item.nickname
								? item.nickname
								: item.firstname + " " + item.lastname
						}`}>
						<LazyLoaderWraper
							slot="media"
							src={
								item.chat_id === loginResponse.data.chat_id
									? notesicon
									: !item.hide_profile_picture
									? item.profile_image
									: user
							}
							height={24}
							width={24}
							alt=""
						/>
						<span
							onClick={() => handleItemClickRemove(item)}
							className="cursor-pointer relative bottom-[-8px] ml-2 mr-[-7px]">
							<MaterialIcon
								size={20}
								icon="close"
								weight={300}
								fill
								color="--f7-heading-color"
							/>
						</span>
					</Chip>
				))}
			</div>

			{isLoading && (
				<div className="flex flex-col justify-center items-center h-4/5">
					<Preloader color="#0e99fe" />
				</div>
			)}

			<List
				ref={contactsList}
				mediaList
				strongIos
				className="search-list contact-list3 searchbar-found mt-0">
				<ListItem
					checkbox
					checked={selectedItems?.find((item) => item.chat_id === myOwnChatId)}
					hideLastSeen
					onChange={() => handleItemClick({ chat_id: myOwnChatId })}
					title={t("My Notes")}>
					<LazyLoaderWraper
						slot="media"
						src={notesicon}
						height={55}
						width={55}
						alt=""
						className="rounded-full align-bottom"
						wrapperclassname="rounded-full align-bottom"
					/>
				</ListItem>
				{chats?.map((chat, index) => (
					<ListItem
						key={index}
						checkbox
						checked={
							selectedItems?.some((item) => item.chat_id === chat.chat_id) ||
							false
						}
						hideLastSeen
						onChange={() => handleItemClick(chat)}
						title={`${
							chat.nickname
								? chat.nickname
								: chat.firstname + " " + chat.lastname
						}`}>
						<LazyLoaderWraper
							slot="media"
							src={!chat.hide_profile_picture ? chat.profile_image : user}
							height={55}
							width={55}
							alt=""
							className="rounded-full align-bottom"
							wrapperclassname="rounded-full align-bottom"
						/>
					</ListItem>
				))}
			</List>
			<List
				ref={notFoundList}
				strongIos
				outlineIos
				dividersIos
				className="searchbar-not-found contacts-not-found3">
				<ListItem title="No chat found" />
			</List>
			<Button
				disabled={selectedItems.length < 1}
				onClick={forward}
				className="fixed bottom-[20px] right-[30px] rounded-full flex items-center justify-center active-zoom z-10 w-[48px] h-[48px] bg-[--f7-md-primary] cursor-pointer">
				<Send size="20" color="#FFFFFF" variant="Bold" />
			</Button>
		</Page>
	);
};

export default MessageForward;
