import $ from "dom7";
import React, {
	useRef,
	useState,
	useContext,
	useEffect,
	useMemo,
	useCallback,
	memo,
	useLayoutEffect,
} from "react";
import { useUpdateEffect, useWindowSize } from "react-use";
import useLocalStorageState from "use-local-storage-state";
import { useSelector, useDispatch } from "react-redux";
import {
	Navbar,
	Page,
	Button,
	f7,
	NavLeft,
	NavRight,
	useStore,
	Panel,
	Popover,
	Popup,
	SkeletonText,
	SkeletonAvatar,
	Fab,
	Badge,
	Toggle,
	Toolbar,
	Link,
	Tabs,
	Tab,
	List,
	ListItem,
	PageContent,
	Messages,
} from "framework7-react";
import { Menu, Item, useContextMenu } from "react-contexify";
import "react-contexify/dist/ReactContexify.css";
import Lightbox from "../../components/modals/lightbox";
import LazyLoaderWraper from "../../components/misc/lazyloaderWraper";
import { Call, CallCalling, Setting2, Video } from "iconsax-react";
import { REACTIONS } from "../../constants";
import notesicon from "../../assets/images/icons/notes-icon.svg";
import userimage from "../../assets/images/placeholders/user.png";
import groupimage from "../../assets/images/placeholders/group.png";
import conversation_img from "../../assets/images/placeholders/conversation.svg";
import hand from "../../assets/images/placeholders/hand.gif";
import "./style.css";
import ContactInfo from "../chats/panels/contact-info";
import { userProfileService } from "../../redux/features/userSlice/profile";
import GroupInfo from "../chats/panels/group-info";
import EditGroupInfo from "../chats/panels/edit-group-info";
import GroupMembers from "../chats/panels/group-members";
import AddMoreParticipants from "../chats/panels/add-more-participants";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../js/db";
import MaterialIcon from "../../components/misc/materialIcon";
import {
	calculateLastseen,
	removeMessageContext,
	imageURLtoBlobGenerator,
	unixTimestampToDate,
	convertToMillisecondsPrecision,
	imageURLtoBlobGeneratorAsync,
} from "../../utils/functions";
import { SocketContext } from "../../socket";
import { ENDPOINTS } from "../../constants/socket";
import DownloaderDialog from "../../components/misc/downloaderDialog";
import MessageForward from "../chats/panels/message-forward";
import EmojiPicker, { Emoji } from "emoji-picker-react";
import { useTranslation } from "react-i18next";
import SearchPanel from "../chats/panels/search-panel";
import StatusIcon from "../../components/misc/statusIcon";
import MessageInfoPanel from "../chats/panels/message-info";
import { getMessageInfoService } from "../../redux/features/chatSlice/messageInfo";
import LiveMap from "../../components/modals/liveMap";
import { chatMessageService } from "../../redux/features/chatSlice/chatMessages";
import { getReactionDetailService } from "../../redux/features/chatSlice/reactionDetail";
import Messagebar from "../../components/inputs/messagebar";
import Audiobar from "../../components/inputs/audiobar";
import DateHeader from "../../components/misc/DateHeader";
import RenderMessage from "../../components/messages/renderMessage";
import ChatShimmer from "../../components/misc/chatShimmer";

const SingleChatPage = memo(({ id, type, f7router }) => {
	id = id !== "null" ? (id !== "posh_ai" ? parseInt(id) : id) : id;
	const activeConversation = useStore("activeConversation");
	const activeConversationType = useStore("activeConversationType");
	const activeMessage = useStore("activeMessage");
	if (activeConversation) {
		id =
			activeConversation !== "null"
				? activeConversation !== "posh_ai"
					? parseInt(activeConversation)
					: activeConversation
				: activeConversation;
	}
	if (activeConversationType) {
		type = activeConversationType;
	}
	const { t } = useTranslation();
	const { width } = useWindowSize();
	const socket = useContext(SocketContext);
	const dispatch = useDispatch();
	const userStatus = useStore("userStatus");
	const callChatId = useStore("callChatId");
	const [statusMessage, setStatusMessage] = useState({});
	const backLinkVisible = useMemo(() => width < 775, [width]);
	const [selectedMessage, setSelectedMessage] = useState(null);
	const [
		messageContext,
		setMessageContext,
		{ removeItem: removeMessageContext },
	] = useLocalStorageState("messageContext");
	const [mode, setMode] = useState("view");
	const [membersGroup, setMembersGroup] = useState(null);
	const [addMoreParticipants, setAddMoreParticipants] = useState(null);
	const profileServiceDispatched = useRef(false);
	const [reactionsOpen, setReactionsOpen] = useState(false);
	const typing = useStore("typing");
	const [typingText, setTypingText] = useState("");
	const [showScrollToBottom, setShowScrollToBottom] = useState(false);
	const [AIWebSearch, setAIWebSearch] = useLocalStorageState("AIWebSearch", {
		defaultValue: false,
	});
	const [reactions, setReactions] = useState(null);
	const [allReactionsCount, setAllReactionsCount] = useState(null);
	const [loadingState, setLoadingState] = useState("loading");
	const [myExistingReactions, setMyExistingReactions] = useState([]);
	const [infoPanel, setInfoPanel] = useState(false);
	const [forwardPanel, setForwardPanel] = useState(false);
	const [searchPanel, setSearchPanel] = useState(false);
	const [msgInfoPanel, setMsgInfoPanel] = useState(false);
	const [callInProgress, setCallInProgress] = useState(0);

	useUpdateEffect(() => {
		f7.sheet.close();
		f7.popup.close();
		f7.panel.close();
		f7.popover.close();
		f7.store.dispatch("resetSelectedMessages");
		setInfoPanel(false);
		setForwardPanel(false);
		setSearchPanel(false);
		setMsgInfoPanel(false);
		setLoadingState("loading");
		setPageSize(20);
		setReactionsOpen(false);
		setSelectedMessage(null);
		setMode("view");
		setMembersGroup(null);
		setAddMoreParticipants(null);
		setTypingText("");
		setReactions(null);
		setAllReactionsCount(null);

		if (id !== "null") {
			typeof id !== "string" &&
				id !== loginResponse.data.chat_id &&
				db.chats
					.where({ chat_id: id })
					.modify({ un_read_count: 0 })
					.then(() => {});

			if (id !== "posh_ai") {
				dispatch(
					chatMessageService({
						chat_id: id,
						from_search: 0,
						offset: 0,
						swipe_up: 0,
					})
				);
			}
			f7 &&
				f7.on("searchMessage", (elId, animate = true) => {
					const el = document.getElementById(elId);

					el &&
						el.scrollIntoView({
							behavior: "smooth",
							block: "center",
						});

					if (animate) {
						el.classList.add("flash");

						setTimeout(() => {
							el.classList.remove("flash");
						}, 3000);
					}
				});

			if (activeMessage) {
				const observer = new MutationObserver((mutations) => {
					mutations.forEach((mutation) => {
						if (mutation.type === "childList") {
							const messageElement = document.getElementById(activeMessage);
							if (messageElement) {
								observer.disconnect();
								f7.emit("searchMessage", activeMessage);
								f7.store.dispatch("setActiveMessage", null);
							}
						}
					});
				});

				observer.observe(document.body, {
					childList: true,
					subtree: true,
				});
			}

			if (typeof id !== "string" && id > 0) {
				socket?.emit(
					ENDPOINTS.READ_ALL_MESSAGES,
					JSON.stringify({
						chat_id: id,
						test: 2,
					})
				);
				socket?.emit(
					ENDPOINTS.CHECK_CALL_STATUS,
					JSON.stringify({
						chatId: id,
					}),
					async (response) => {
						console.log(
							"ENDPOINTS.CHECK_CALL_STATUS",
							ENDPOINTS.CHECK_CALL_STATUS,
							response
						);
						if (response && response.started == true) {
							setCallInProgress(id);
						} else {
							setCallInProgress(0);
						}
					}
				);
			}
		}
	}, [id]);
	const loadingTimeout = useRef(null);
	const [firstRender, setFirstRender] = useState(true);
	const initialLoad = useRef(true);
	const allowInfinite = useRef(true);
	const [selectedLocationMessage, setSelectedLocationMessage] = useState(null);
	const [showMessagebar, setShowMessagebar] = useState(true);
	const [showAudiobar, setShowAudiobar] = useState(false);
	const [messagebarReason, setMessagebarReason] = useState("");
	const scrollBottomThreshold = useMemo(
		() => (f7.device.electron ? 1009 : 945),
		[width]
	);
	const pageContent = useRef(null);
	const messagesRef = useRef(null);
	const [pageSize, setPageSize] = useState(20);
	const [isLastPage, setIsLastPage] = useState(false);
	const selectedMessages = useStore("selectedMessages");
	const unseenMessages = useStore("unseenMessages");
	const showUnseen = useRef(true);

	const { loginResponse } = useSelector((state) => state.loginSlice);

	// const { drop, dropScope, clear, getCachingNodes } = useAliveController();

	// if (getCachingNodes().length > 3) {
	// 	drop(getCachingNodes()[0].name);
	// 	dropScope(getCachingNodes()[0].name);
	// }

	const [unreadCount, setUnreadCount] = useState(0);

	const _messages = useLiveQuery(async () => {
		if (!id || id === "null")
			return { groupedMessages: [], mergedData: [], count: 0 };
		const chatId = id;
		// console.time(`ChatItem in SingleChatPage uselivequery count time ${id}`);
		const count = await db.messages.where({ chat_id: id }).count();
		const t_count = await db.translatedMessages.where({ chat_id: id }).count();
		// console.timeEnd(`ChatItem in SingleChatPage uselivequery count time ${id}`);
		const offset = count - pageSize > 0 ? count - pageSize : 0;
		const translation_offset = t_count - pageSize > 0 ? t_count - pageSize : 0;
		// console.time(
		// 	`ChatItem in SingleChatPage uselivequery messages query time ${id}`
		// );
		const [data, translatedData] = await Promise.all([
			db.messages.where("chat_id").equals(chatId).offset(offset).toArray(),
			db.translatedMessages
				.where("chat_id")
				.equals(chatId)
				.offset(translation_offset)
				.toArray(),
		]);
		// console.timeEnd(
		// 	`ChatItem in SingleChatPage uselivequery messages query time ${id}`
		// );
		if (data.length === 0)
			return { groupedMessages: [], mergedData: [], count };

		const translatedMap = new Map(translatedData?.map((msg) => [msg.id, msg]));

		const mergedData = data.map(
			(msg) =>
				translatedMap?.get(msg.id) || {
					...msg,
					sender_id:
						typeof msg.sender_id === "string"
							? parseInt(msg.sender_id)
							: msg.sender_id,
					nickname: msg.nickname || msg.sender_name,
					reactions: msg.reactions || {
						cry: 0,
						excited: 0,
						like: 0,
						heart: 0,
						sad: 0,
						thanks: 0,
						laugh: 0,
						smile: 0,
						angry: 0,
						dislike: 0,
						my_reaction: "",
						my_reactions: [],
					},
					group_name: msg.group_name === "null" ? null : msg.group_name,
					is_viewed: msg.is_viewed ?? (msg.is_read === 2 ? 1 : 0),
				}
		);

		mergedData.sort((a, b) => {
			const timeDifference =
				convertToMillisecondsPrecision(parseInt(a.unix_time)) -
				convertToMillisecondsPrecision(parseInt(b.unix_time));

			if (timeDifference !== 0) {
				return timeDifference;
			}

			return a.id - b.id;
		});

		// Create a Map to store grouped messages
		const groupedMessages = new Map();

		// First pass: group messages by group_id
		for (const message of mergedData) {
			// Only group messages that have both is_group=true AND a group_id
			if (message.is_group && message.group_id) {
				const groupId = message.group_id;
				if (!groupedMessages.has(groupId)) {
					groupedMessages.set(groupId, []);
				}
				groupedMessages.get(groupId).push(message);
			}
		}

		// Create a combined list of all messages (grouped and ungrouped)
		const combinedMessages = [];

		// Add ungrouped messages
		for (const message of mergedData) {
			if (!message.is_group || !groupedMessages.has(message.group_id)) {
				combinedMessages.push({
					type: "message",
					content: message,
					unix_time: convertToMillisecondsPrecision(
						parseInt(message.unix_time)
					),
				});
			}
		}

		// Add grouped messages
		for (const [groupId, messages] of groupedMessages) {
			messages.sort(
				(a, b) =>
					convertToMillisecondsPrecision(parseInt(a.unix_time)) -
					convertToMillisecondsPrecision(parseInt(b.unix_time))
			);

			combinedMessages.push({
				type: "message",
				content: messages,
				unix_time: convertToMillisecondsPrecision(
					parseInt(messages[0].unix_time)
				),
			});
		}

		// Sort all messages by unix_time
		combinedMessages.sort((a, b) => a.unix_time - b.unix_time);

		// Process sorted messages into final structure with date headers
		const flatList = [];
		const unreadList = [];
		let currentDate = null;
		const unreadHeaderAdded = new Set();

		for (const item of combinedMessages) {
			const messages = Array.isArray(item.content)
				? item.content
				: [item.content];
			const date = unixTimestampToDate(item.unix_time);

			const isUnread = Array.isArray(item.content)
				? messages.some(
						(msg) =>
							// Changed condition to handle both is_read and is_viewed
							(type === "single"
								? msg.is_read < 2 && msg.is_read !== undefined
								: msg.is_viewed !== 1 && msg.is_viewed !== undefined) &&
							parseInt(msg.sender_id) !== loginResponse.data.id
				  )
				: (type === "single"
						? item.content.is_read < 2 && item.content.is_read !== undefined
						: item.content.is_viewed !== 1 &&
						  item.content.is_viewed !== undefined) &&
				  parseInt(item.content.sender_id) !== loginResponse.data.id;

			if (isUnread) {
				if (!unreadHeaderAdded.has(date)) {
					unreadList.push({
						type: "dateHeader",
						content: date,
						parent: "unread",
					});
					unreadHeaderAdded.add(date);
				}
				unreadList.push({
					type: "message",
					content: item.content,
					parent: date,
				});
			} else {
				if (date !== currentDate) {
					flatList.push({ type: "dateHeader", content: date });
					currentDate = date;
				}
				flatList.push({
					type: "message",
					content: item.content,
					parent: date,
				});
			}
		}

		if (unreadList.length > 0) {
			flatList.push({ type: "dateHeader", content: "Unread" });
			flatList.push(...unreadList);
		}

		if (showUnseen.current || firstRender) {
			const unreadMessages = mergedData.filter(
				(msg) =>
					(type === "single" ? msg.is_read < 2 : msg.is_viewed !== 1) &&
					parseInt(msg.sender_id) !== loginResponse.data.id
			);

			setUnreadCount(unreadMessages.length);
		}

		if (flatList.length > 0) allowInfinite.current = true;

		return { groupedMessages: flatList, mergedData, count };
	}, [id, pageSize]);

	const messageCount = useMemo(() => _messages?.count, [_messages?.count]);

	const messages = useMemo(
		() => _messages?.groupedMessages,
		[_messages?.groupedMessages]
	);

	const mergedData = useMemo(
		() => _messages?.mergedData,
		[_messages?.mergedData]
	);

	useEffect(() => {
		if (id !== "null") {
			if (typing && typing[id]) {
				const _typing = typing[id];
				if (_typing && _typing instanceof Array) {
					if (_typing?.length > 1) {
						const typingText = _typing?.map((data) => data.user?.split(" ")[0]);
						setTypingText(typingText.join(", ") + " is " + t("typing..."));
					} else {
						setTypingText(
							_typing[0]?.user?.split(" ")[0] + " is " + t("typing...")
						);
					}
				} else if (_typing) {
					setTypingText(t("typing..."));
				} else {
					setTypingText("");
				}
			} else {
				setTypingText("");
			}
		} else {
			setTypingText("");
		}
	}, [typing]);

	const _chat = useLiveQuery(
		async () => {
			if (!id || id === "null") return null;
			const data = await db.chats.where({ chat_id: id }).first();
			return data;
		},
		[id],
		null
	);

	const chat = useMemo(() => _chat, [_chat]);

	const _members = useLiveQuery(
		async () => {
			if (!id || id === "null") return [];

			const data = await db.members.where({ chat_id: id }).toArray();

			return data;
		},
		[id],
		[]
	);

	const { members, targetMembers } = useMemo(() => {
		return {
			members: _members,
			targetMembers:
				_members?.filter((member) => member.id !== loginResponse.data.id) || [],
		};
	}, [_members]);

	const { chatMessagesResponse, isLoading, error } = useSelector(
		(state) => state.chatMessageSlice
	);

	// useUpdateEffect(() => {
	// 	if (id !== "null") {
	// 		if (isLoading && !initialLoad) setShowMessagePreloader(true);
	// 		else setShowMessagePreloader(false);
	// 	}
	// }, [isLoading, initialLoad]);

	// useUpdateEffect(() => {
	// 	if (!isLoading && typeof id !== "string" && id > 0) {
	// 		socket?.emit(
	// 			ENDPOINTS.READ_ALL_MESSAGES,
	// 			JSON.stringify({
	// 				chat_id: id,
	// 				test: 2,
	// 			})
	// 		);
	// 	}
	// }, [isLoading, id]);

	const { getReactionDetailResponse } = useSelector(
		(state) => state.reactionDetailSlice
	);

	const _userReactions = useLiveQuery(
		async () => {
			if (!messageContext || Array.isArray(messageContext)) return {};

			const data = await db.reactions
				.where({ message_id: messageContext?.id })
				.first();

			if (data && data.reactions) {
				let _reactions = Object.keys(data.reactions).filter(
					(key) => data.reactions[key].length > 0
				);
				let _allCount = 0;
				_reactions.forEach((reaction) => {
					_allCount += data.reactions[reaction].length;
				});
				setReactions(_reactions);
				setAllReactionsCount(_allCount);
			}

			return data;
		},
		[messageContext, getReactionDetailResponse],
		{}
	);

	useEffect(() => {
		if (id !== "null") {
			if (id === 11) {
				setShowMessagebar(false);
				return;
			}

			if (chat?.type == "single") {
				if (chat.is_blocked == 1) {
					setShowMessagebar(false);
				} else {
					setShowMessagebar(true);
				}
				return;
			} else {
				setShowMessagebar(true);
			}
		} else setShowMessagebar(false);
	}, [chat, id]);

	const userReactions = useMemo(() => _userReactions, [_userReactions]);

	let displayedNames = useMemo(() => {
		let _displayedNames = [];
		if (
			targetMembers &&
			Array.isArray(targetMembers) &&
			targetMembers.length > 0
		) {
			const targetName = targetMembers.map((member) =>
				member.firstname ? member.firstname : member.username
			);
			_displayedNames = targetName;

			const loginUserId = loginResponse.data.id;
			const loginUserName = `You`;

			if (
				members.some((member) => member.id === loginUserId) &&
				_displayedNames.length < 10
			) {
				_displayedNames.push(loginUserName);
			}
		}
		return _displayedNames;
	}, [targetMembers, loginResponse, members]);

	const callStart = (mode, chatType) => {
		f7.store.dispatch("setCallChatId", id);
		f7.store.dispatch("setCallType", mode);
		f7.store.dispatch("setCallDirection", "outgoing");
		f7.store.dispatch("setCallChatType", chatType);
		f7.store.dispatch("setShowCallScreen", true);
	};

	useEffect(() => {
		if (
			targetMembers &&
			Array.isArray(targetMembers) &&
			targetMembers.length > 0 &&
			!profileServiceDispatched.current
		) {
			if (type === "single") {
				const targetMemberIds = targetMembers.map((member) => member.id);
				if (targetMemberIds.length && id !== "posh_ai") {
					dispatch(
						userProfileService({
							user_id: targetMemberIds[0],
						})
					);
					profileServiceDispatched.current = true;
					socket.emit(
						ENDPOINTS.CHECK_USER_STATUS,
						JSON.stringify({
							user_id: targetMemberIds[0],
						}),
						(data) => {
							f7.store.dispatch("setUserStatus", data);
						}
					);
				}
			}
		}
	}, [targetMembers]);

	const { userProfileResponse } = useSelector((state) => state.profileSlice);

	useUpdateEffect(() => {
		setTimeout(() => {
			profileServiceDispatched.current = false;
		}, 1000);
	}, [userProfileResponse]);

	useEffect(() => {
		if (chat?.chat_id == 11) {
			const state = t("Official KT Messenger Account");
			setStatusMessage(state);
		} else {
			const state = userStatus[chat?.user_id]
				? userStatus[chat?.user_id]?.status
					? t("online")
					: !userStatus[chat?.user_id].last_online ||
					  userStatus[chat?.user_id].last_online == ""
					? t("Away")
					: `${t("Last seen")} ${calculateLastseen(
							userStatus[chat?.user_id].last_online
					  )}`
				: t("Away");

			setStatusMessage(state);
		}
	}, [userStatus, chat]);

	useEffect(() => {
		f7 &&
			f7.on("setSelectChat", (mid) => {
				if (!mid) {
					db.messages
						.where({ chat_id: id })
						.filter((msg) => {
							return msg.type === "image" || msg.type === "video";
						})
						.toArray()
						.then((msgs) => {
							const media = msgs.sort((a, b) => b.unix_time - a.unix_time);
							f7.store.dispatch("setSelectedMessageId", media[0].id);
							setSelectedMessage(media[0].id);
						});
				} else {
					f7.store.dispatch("setSelectedMessageId", mid);
					setSelectedMessage(mid);
				}
			});

		return () => {
			f7 && f7.off("setSelectChat");
		};
	});

	// const [lastId, setLastId] = useState(null);

	// useUpdateEffect(() => {
	// 	if (lastId) {
	// 		const el = document.getElementById(lastId);
	// 		if (el) {
	// 			el.scrollIntoView({ alignToTop: true, behavior: "instant" });
	// 		}
	// 	}
	// }, [lastId]);

	const hideReply = ({ props }) => {
		if (props instanceof Array || !members || id === "posh_ai") {
			return true;
		} else {
			// const existInMembers = members.find(
			// 	(m) => m.id === loginResponse.data.id
			// );
			// if (existInMembers) {
			// 	return false;
			// } else {
			// 	return true;
			// }
			return id !== "null" && id !== "posh_ai" && id > 11 ? false : true;
		}
	};
	const hideForward = ({ props }) => {
		if (
			props.type === "call" ||
			id === "posh_ai" ||
			id === 11 ||
			!props.allow_forwarding ||
			parseInt(chat.allow_forwarding) === 0
		) {
			return true;
		} else return false;
	};
	const hideEdit = ({ props }) => {
		if (
			props instanceof Array ||
			props.sender_id != loginResponse.data.id ||
			props.type === "location" ||
			props.type === "contact" ||
			props.type === "mms" ||
			props.type === "call" ||
			id === "posh_ai"
		) {
			return true;
		} else return false;
	};
	const hideInfo = ({ props }) => {
		const singleChat =
			chat?.type === "single"
				? Array.isArray(props)
					? props[0].sender_id != loginResponse.data.id
					: props.sender_id != loginResponse.data.id
				: false;
		if (Array.isArray(props)) {
			if (singleChat || msgInfoPanel || id === "posh_ai" || id === 11) {
				return true;
			}
			return false;
		} else {
			if (
				singleChat ||
				props.type === "call" ||
				msgInfoPanel ||
				id === "posh_ai" ||
				id === 11
			) {
				return true;
			}
			return false;
		}
	};
	const hideCopy = ({ props }) => {
		if (props instanceof Array) return true;
		else if (props.type === "image" || props.type === "text") return false;
		else return true;
	};
	/*const hideDelete = ({ props }) => {
		if (props.sender_id === loginResponse.data.id) return false;
		else if (type === "group" && chat?.owner_id === loginResponse.data.id)
			return false;
		else return true;
	};*/

	const copyMessage = (context) => {
		if (context) {
			navigator.clipboard.writeText(context.message);
			f7.toast
				.create({
					text: t("Message copied to clipboard"),
					position: "top",
					closeTimeout: 2000,
				})
				.open();
		}
	};

	const copyImgToClipboard = async (context) => {
		if (context) {
			try {
				imageURLtoBlobGenerator(context.audio_url, async (blobs) => {
					if (blobs.length === 0) return;
					const data = {};
					blobs.map((blob) => {
						data[`${blob.type !== "image/png" ? "web " : ""}${blob.type}`] =
							blob;
					});
					await navigator.clipboard.write([new window.ClipboardItem(data)]);
				});
			} catch (ex) {
				console.error("Error copying image to clipboard:", ex);
			}
		}
	};

	const copyMultipleMessage = useCallback(async () => {
		const context = selectedMessages;
		if (context) {
			const blobs = {};
			let combinedText = "";
			const promises = [];

			const processImageURL = (message) => {
				return new Promise(async (resolve, reject) => {
					try {
						const imageBlobs = await imageURLtoBlobGeneratorAsync(
							message.audio_url
						);
						imageBlobs.map((blob) => {
							if (blob.type === "image/png") {
								blobs["image/png"] = blob;
							}
						});

						if (message.caption?.trim()?.length > 0) {
							const timestamp = new Date(
								convertToMillisecondsPrecision(message.unix_time)
							).toLocaleString();
							const sender =
								message.sender_id === loginResponse.data.id
									? ""
									: message.sender_name;
							combinedText += `[${timestamp}] ${sender}: ${message.caption}\n`;
						}
						resolve();
					} catch (ex) {
						reject(ex);
					}
				});
			};

			const processText = (message) => {
				const timestamp = new Date(
					convertToMillisecondsPrecision(message.unix_time)
				).toLocaleString();
				const sender =
					message.sender_id === loginResponse.data.id
						? ""
						: message.sender_name;
				combinedText += `[${timestamp}] ${sender}: ${message.message}\n`;
			};

			for (const message of context) {
				if (message.type === "text") {
					processText(message);
				} else if (message.type === "image") {
					promises.push(processImageURL(message));
				} else {
					continue;
				}
			}

			// Wait for all image processing promises to resolve
			await Promise.all(promises);

			// Create a single ClipboardItem with all the blobs and text
			const clipboardData = {};
			Object.keys(blobs).forEach((key) => {
				clipboardData[key] = blobs[key];
			});
			if (combinedText) {
				clipboardData["text/plain"] = new Blob([combinedText.trim()], {
					type: "text/plain",
				});
			}

			try {
				await navigator.clipboard.write([
					new window.ClipboardItem(clipboardData),
				]);
			} catch (ex) {}
			f7.store.dispatch("resetSelectedMessages");
		}
	}, [selectedMessages]);

	const DeleteMessage = (messagesToDelete) => {
		function deleteSingleMessage(context, dialog, meonly = 0) {
			if (context) {
				// Check if the message exists in the database before attempting to delete it
				db.messages
					.get(context.id)
					.then((message) => {
						if (message) {
							// If the message exists, proceed with deletion
							socket.emit(
								ENDPOINTS.DELETE_MESSAGE,
								JSON.stringify({
									msg_id: context.id,
									delete_for_me: meonly,
									chat_id: context.chat_id,
								}),
								(response) => {
									dialog.close();
								}
							);

							db.messages
								.delete(context.id)
								.then(function (deleteCount) {
									f7.store.dispatch("removeSelectedMessages", context.id);

									db.messages
										.where({ chat_id: context.chat_id })
										.toArray()
										.then((msgs) => {
											if (!msgs || msgs.length === 0) return;

											msgs?.sort(
												(a, b) =>
													convertToMillisecondsPrecision(
														parseInt(a.unix_time)
													) -
													convertToMillisecondsPrecision(parseInt(b.unix_time))
											);
											const lastMsg = msgs[msgs.length - 1];
											db.chats.get(lastMsg?.chat_id).then((chat) => {
												const chatData = {
													message: lastMsg.message,
													...(chat.type === "group" && {
														firstname: lastMsg.sender_name,
													}),
													message_sender_id: parseInt(lastMsg.sender_id),
													unix_time: lastMsg.unix_time,
													msg_type: lastMsg.type,
												};

												db.chats
													.where({
														chat_id: lastMsg.chat_id,
													})
													.modify(chatData)
													.then((id) => {})
													.catch((ex) => {});
											});
										})
										.catch((error) => {});
								})
								.catch((error) => {
									f7.toast
										.create({
											text: "Error in deleting message",
										})
										.open();
									console.error("Error in deleting message: ", error.message);
								});
						} else {
							f7.toast
								.create({
									text: "Message not found in database, cannot delete.",
								})
								.open();
						}
					})
					.catch((error) => {
						f7.toast.create({ text: "Error in deleting message" }).open();
						console.error(
							"Error in checking message existence: ",
							error.message
						);
					});
			}
		}

		var is_sent_by_me =
			messagesToDelete instanceof Array
				? messagesToDelete[0].sender_id == loginResponse.data.id
				: messagesToDelete.sender_id == loginResponse.data.id;

		const buttons = [
			{
				text: t("Cancel"),
				cssClass: "custom-Cancel-button",
				onClick: function () {
					// Handle cancel logic
					f7.dialog.close();
				},
			},
		];

		buttons.unshift({
			text: t("Delete for me"),
			cssClass: "custom-clear-button",
			onClick: function (dialog) {
				if (messagesToDelete instanceof Array) {
					messagesToDelete.forEach((message) => {
						deleteSingleMessage(message, dialog, 1);
					});
				} else {
					deleteSingleMessage(messagesToDelete, dialog, 1);
				}
			},
		});

		if (
			is_sent_by_me &&
			id !== "posh_ai" &&
			id !== loginResponse.data.chat_id
		) {
			buttons.unshift({
				text: t("Delete for everyone"),
				cssClass: "custom-clear-button",
				onClick: function (dialog) {
					if (messagesToDelete instanceof Array) {
						messagesToDelete.forEach((message) => {
							deleteSingleMessage(message, dialog);
						});
					} else {
						deleteSingleMessage(messagesToDelete, dialog);
					}
				},
			});
		}

		f7.dialog
			.create({
				title: t("Delete message?"),
				text: `${t(
					"Deleting a message for yourself allows you to delete your copy of messages you've sent or received"
				)}
            
                    <span class="text_Area">
                    ${t("This has no effect on your recipients' chats")}
                    </span>
                `,
				buttons: buttons,
			})
			.open();
	};

	const selectMessage = (message) => {
		if (Array.isArray(message)) {
			message.forEach((msg) => {
				const index = selectedMessages.findIndex((msg) => msg.id === msg.id);
				index === -1
					? f7.store.dispatch("addSelectedMessages", msg)
					: f7.store.dispatch("removeSelectedMessages", msg);
			});
		} else {
			const index = selectedMessages.findIndex((msg) => msg.id === message.id);
			index === -1
				? f7.store.dispatch("addSelectedMessages", message)
				: f7.store.dispatch("removeSelectedMessages", message);
		}
	};

	useEffect(() => {
		if (!messageContext) {
			setMyExistingReactions([]);
			return;
		}
		setMyExistingReactions(messageContext.reactions?.my_reactions);
	}, [messageContext]);

	useEffect(() => {
		if (!myExistingReactions) return;

		if (!reactionsOpen || myExistingReactions?.length > 0) {
			const allReactionEl = document.querySelectorAll(
				`.EmojiPickerReact.open.has-my-reactions > ul > li > button.epr-btn`
			);
			allReactionEl?.forEach((reactionEl) =>
				reactionEl?.classList?.remove("reactions-active")
			);

			setTimeout(() => {
				let reactionsMapped = [];
				myExistingReactions?.map((reaction) => {
					const emojiCode = REACTIONS[reaction];
					reactionsMapped.push(emojiCode);
					const reactionEl = document.querySelector(
						`.EmojiPickerReact.open.has-my-reactions > ul > li > button.epr-btn[data-unified='${emojiCode}']`
					);
					reactionEl?.classList?.add("reactions-active");
				});
				const nonActiveReactions = Object.values(REACTIONS).filter(
					(r) => !reactionsMapped.includes(r)
				);
				nonActiveReactions.forEach((reaction) => {
					const reactionEl = document.querySelector(
						`.EmojiPickerReact.open.has-my-reactions > ul > li > button.epr-btn[data-unified='${reaction}']`
					);
					reactionEl?.classList?.remove("reactions-active");
				});
			}, 100);
		}

		return () => {
			document
				.querySelector(`.reactions-active`)
				?.classList.remove("reactions-active");
		};
	}, [myExistingReactions, reactionsOpen]);

	const removeReaction = (reaction, msgId) => {
		socket.emit(
			ENDPOINTS.REMOVE_REACTION,
			JSON.stringify({
				message_id: msgId,
				reaction: reaction,
				chat_id: parseInt(id),
			}),
			async (response) => {
				if (response?.status) {
					try {
						await db.messages.where({ id: msgId }).modify((value, ref) => {
							ref.value = {
								...value,
								reactions: { ...response.reactions },
							};
						});
						setMyExistingReactions((prev) =>
							prev.filter((r) => r !== reaction)
						);
						dispatch(getReactionDetailService({ message_id: msgId }));
					} catch (ex) {}
				} else {
					f7.toast.create({
						text: "Something went wrong",
						position: "center",
						closeTimeout: 2000,
					});
				}
			}
		);
	};

	const handleReaction = (reaction) => {
		f7.popover.close();
		reaction = Object.keys(REACTIONS).find(
			(key) => REACTIONS[key] === reaction.unified
		);
		const context = messageContext;

		if (!context) return;

		if (myExistingReactions?.includes(reaction)) {
			removeReaction(reaction, context.id);
		} else {
			socket.emit(
				ENDPOINTS.ADD_REACTION,
				JSON.stringify({
					message_id: context.id,
					reaction: reaction,
					chat_id: id,
				}),
				async (response) => {
					if (response.status) {
						removeMessageContext();
						await db.messages
							.where({ id: response.message_id })
							.modify((value, ref) => {
								ref.value = {
									...value,
									reactions: { ...response.reactions },
								};
							});
						setMyExistingReactions((prev) =>
							Array.isArray(prev) ? [...prev, reaction] : [reaction]
						);
						dispatch(
							getReactionDetailService({
								message_id: response.message_id,
							})
						);
					}
				}
			);
		}
	};

	const { show } = useContextMenu({
		id: "chat-menu",
	});

	const displayMenu = useCallback(
		(message, e) => {
			if (msgInfoPanel) return;
			console.log(document.getElementsByTagName("html")[0].classList);
			if (
				document
					.getElementsByTagName("html")[0]
					.classList.contains("with-panel")
			) {
				f7.panel.close();
			}
			show({
				event: e,
				props: message,
			});
		},
		[msgInfoPanel]
	);

	const toggleAudioInput = () => {
		setShowMessagebar((state) => !state);
		setShowAudiobar((state) => !state);
	};

	useUpdateEffect(() => {
		if (chatMessagesResponse?.data?.is_last_page) {
			setIsLastPage(true);
		}
	}, [chatMessagesResponse]);

	useUpdateEffect(() => {
		if (messages && messages?.length > 0 && initialLoad.current)
			initialLoad.current = false;

		if (messages && messages?.length > 0 && !allowInfinite.current)
			setTimeout(() => {
				allowInfinite.current = true;
			}, 1000);
	}, [messages]);

	const handleScrollToBottom = (messageId = null) => {
		if (!pageContent.current.el) return;

		setTimeout(() => {
			// pageContent.current.el.scrollTo({
			// 	top: 0,
			// 	left: 0,
			// 	behavior: "smooth",
			// });
			// setShowScrollToBottom(false);
			const startTime = performance.now();
			const startScrollTop = pageContent.current.el.scrollTop;

			function scroll(timestamp) {
				const elapsed = timestamp - startTime;
				const progress = Math.min(elapsed / 500, 1);

				// Calculate the target scroll position. Since we want to scroll to the top
				// (which is visually the bottom of the content), the scroll target is 0.
				const scrollTo = startScrollTop - startScrollTop * progress;
				pageContent.current.el.scrollTop = scrollTo;

				if (progress < 1) {
					requestAnimationFrame(scroll);
				} else {
					// Ensure it ends exactly at the top (visually the bottom of the content)
					pageContent.current.el.scrollTop = 0;
					showUnseen.current = false;
				}
			}

			requestAnimationFrame(scroll);
		}, 50);
	};

	const conditionalScrollToBottom = (messageId) => {
		if (id !== "null" && messageId) {
			if (!showScrollToBottom) {
				f7.emit("scrollToBottom", messageId);
			}
		}
	};

	const loadMoreMessages = useCallback(async () => {
		if (id === "null" || !allowInfinite.current) return;
		allowInfinite.current = false;
		if (mergedData.length === 0) return;
		if (id !== null && !isLastPage) {
			dispatch(
				chatMessageService({
					chat_id: id,
					from_search: 0,
					offset: mergedData[0].id,
					swipe_up: 0,
				})
			);
			setPageSize((prev) => prev + 20);
		}
	}, [isLastPage, id, mergedData]);

	const hideUnread = (check_bottom = false) => {
		const condition = check_bottom
			? showScrollToBottom === false && showUnseen.current
			: true;
		const timeout = check_bottom ? 5000 : 0;
		const delay = check_bottom ? 500 : 0;
		if (condition) {
			const element = document.getElementById("unread");
			if (element) {
				setTimeout(() => {
					delay ? element.classList.add("fade-out") : (element.hidden = true);
					setTimeout(() => {
						type === "single"
							? db.messages
									.where("chat_id")
									.equals(id)
									.and(
										(msg) =>
											msg.is_read < 2 && msg.sender_id !== loginResponse.data.id
									)
									.modify((value, ref) => {
										ref.value = {
											...value,
											is_read: 2,
										};
									})
							: db.messages
									.where("chat_id")
									.equals(id)
									.and(
										(msg) =>
											msg.is_viewed < 1 &&
											msg.sender_id !== loginResponse.data.id
									)
									.modify((value, ref) => {
										ref.value = {
											...value,
											is_viewed: 1,
										};
									});
						showUnseen.current = false;
						setFirstRender(false);
						// setCachedUnreadMessages(null);
					}, delay);
				}, timeout);
			} else return;
		} else return;
	};

	useEffect(() => {
		if (!pageContent.current?.el) return;
		pageContent.current.el.scrollTo({
			top: 0,
			left: 0,
			behavior: "auto",
		});
	}, [id]);

	// Add this new function to calculate if we should show the scroll button
	const calculateShowScrollToBottom = useCallback((container) => {
		if (!container) return false;

		const scrollTop = Math.abs(container.scrollTop); // Use absolute value since scroll is negative

		// In reversed layout, when scrollTop is close to 0, we're at the bottom (newest messages)
		// Show button if scrolled up more than 100px from bottom
		return scrollTop > 100;
	}, []);

	const handleScroll = useCallback(() => {
		const container = pageContent.current?.el;
		const shouldShow = calculateShowScrollToBottom(container);
		setShowScrollToBottom(shouldShow);

		if (shouldShow) {
			showUnseen.current = true;
		} else {
			hideUnread(true);
		}

		f7.store.dispatch("setSavedScrollPos", {
			chatId: id,
			pos: container.scrollTop,
			offset: container.scrollHeight - container.scrollTop,
		});

		const parent = container.parentElement;
		if (container && parent) {
			const scrollTop = container.scrollTop || parent.scrollTop;
			const maxScrollTop =
				(container.scrollHeight || parent.scrollHeight) -
				container.clientHeight;
			const percentageFromTop = parseInt(
				Math.abs((scrollTop / maxScrollTop) * 100)
			);

			if (percentageFromTop > 50 && mergedData?.length < messageCount) {
				setPageSize((prev) => prev + 20);
			}
			if (percentageFromTop > 70) {
				loadMoreMessages();
			}
			if (percentageFromTop === 0) {
				hideUnread(true);
			}
		}
	}, [calculateShowScrollToBottom, hideUnread, loadMoreMessages]);

	useUpdateEffect(() => {
		const container = pageContent.current?.el;
		if (!container) return;

		container.addEventListener("scroll", handleScroll);

		// handleScroll();

		return () => {
			container.removeEventListener("scroll", handleScroll);
		};
	}, [mergedData]);

	// Reset scroll state when switching chats
	useEffect(() => {
		setShowScrollToBottom(false);
		showUnseen.current = false;
	}, [id]);

	// Update the Fab component to be more explicit about its visibility conditions
	const showFab = useMemo(() => {
		return (
			showScrollToBottom ||
			(Object.keys(unseenMessages).includes(id) &&
				unseenMessages[id]?.length > 0)
		);
	}, [showScrollToBottom, unseenMessages, id]);

	useEffect(() => {
		if (selectedMessages.length > 0) {
			$(".message, .message > *").on("click", (e) => {
				e.preventDefault();
				e.stopPropagation();
				const id = parseInt(e.target.closest(".message").id);
				const groupMessage = e.target
					.closest(".message")
					.classList.contains("grouped-message");

				function handleSelection(message) {
					const index = selectedMessages.findIndex(
						(msg) => msg.id === message.id
					);
					if (index !== -1) {
						f7.store.dispatch("removeSelectedMessages", message.id);
					} else {
						if (!selectedMessages.some((msg) => msg.id === message.id)) {
							f7.store.dispatch("addSelectedMessages", message);
						}
					}
				}

				if (groupMessage) {
					db.messages
						.where("group_id")
						.equals(id.toString())
						.toArray()
						.then((msgs) => {
							msgs.forEach((msg) => {
								handleSelection(msg);
							});
						});
				} else {
					db.messages.get(id).then((msg) => {
						handleSelection(msg);
					});
				}
			});
		}

		$(".message-bubble, .message-bubble > *").on("contextmenu", async (e) => {
			e.preventDefault();
			e.stopPropagation();
			const msg = await db.messages.get(
				parseInt(e.target.closest(".message").id)
			);
			displayMenu(msg, e);
		});

		return () => {
			$(".message, .message > *").off("click");
			$(".message-bubble, .message-bubble > *").off("contextmenu");
		};
	}, [selectedMessages]);

	useLayoutEffect(() => {
		f7?.on("scrollToBottom", handleScrollToBottom);
		f7.on("clearUnseen", hideUnread);

		return () => {
			f7?.off("scrollToBottom", handleScrollToBottom);
			f7.off("clearUnseen");
		};
	}, []);

	useEffect(() => {
		if (id !== "null") {
			loadingTimeout.current && clearTimeout(loadingTimeout.current);

			if (messageCount > 0 && messages && messages.length > 0) {
				setLoadingState("loaded");
			} else if (
				(messages !== undefined && !isLoading && !error) ||
				(messages !== null && !isLoading && !error)
			) {
				loadingTimeout.current = setTimeout(() => {
					setLoadingState("empty");
				}, 500);
			} else if (isLoading) {
				setLoadingState("loading");
			}
		}

		return () => {
			loadingTimeout.current && clearTimeout(loadingTimeout.current);
		};
	}, [messages, messageCount, isLoading, id]);

	// useEffect(() => {
	// 	Object.keys(unseenMessages).includes(id) &&
	// 		conditionalScrollToBottom(
	// 			unseenMessages[id][unseenMessages[id]?.length - 1]
	// 		);
	// }, [unseenMessages, id]);

	// Add this new effect to handle scrolling to unread header
	useEffect(() => {
		if (messages && messages.length > 0 && unreadCount > 0 && !firstRender) {
			// Create mutation observer to watch for when messages are actually rendered
			const observer = new MutationObserver((mutations) => {
				const unreadHeader = document.getElementById("unread");
				if (unreadHeader) {
					// Disconnect observer once we find the header
					observer.disconnect();

					setTimeout(() => {
						unreadHeader.scrollIntoView({
							behavior: "smooth",
							block: "center",
						});
					}, 100);
				}
			});

			// Start observing the messages container for DOM changes
			const messagesContainer = messagesRef.current?.el;
			if (messagesContainer) {
				observer.observe(messagesContainer, {
					childList: true,
					subtree: true,
				});
			}

			// Cleanup observer on unmount or when dependencies change
			return () => {
				observer.disconnect();
			};
		}
	}, [messages, unreadCount, firstRender]);

	return (
		<Page
			id={`singleChat_${id}`}
			name="singleChat"
			className={`singleChatPage ${id === "null" ? "no-content-overflow" : ""}`}
			onPageInit={() => {}}
			pageContent={false}>
			{/* <KeepAlive id={`${id}_nav`} cacheKey={`${id}_nav`} saveScrollPosition={false}> */}
			{id !== "null" && selectedMessages.length === 0 && (
				<Navbar slot="fixed">
					{backLinkVisible && (
						<NavLeft>
							<Button
								className="p-0 h-[40px]"
								onClick={() => f7router.navigate("/chats/")}>
								<MaterialIcon
									size={24}
									icon="chevron_left"
									weight={300}
									className="text-[40px] text-secondary"
								/>
							</Button>
						</NavLeft>
					)}
					<div
						className={`flex flex-row justify-center ${
							!backLinkVisible && "ml-[20px]"
						} ${
							loginResponse.data.chat_id !== id &&
							id !== "posh_ai" &&
							id !== 11 &&
							"cursor-pointer"
						}`}
						onClick={() => {
							if (
								loginResponse.data.chat_id !== id &&
								id !== "posh_ai" &&
								id !== 11
							)
								setInfoPanel(!infoPanel);
						}}>
						{chat ? (
							<div className="relative shrink-0">
								<LazyLoaderWraper
									src={
										id !== "null" && id === loginResponse.data.chat_id
											? notesicon
											: (!chat?.hide_profile_picture && chat?.profile_image) ||
											  chat?.profile_image?.length > 0
											? chat?.profile_image
											: type === "single"
											? userimage
											: groupimage
									}
									placeholder={type === "single" ? userimage : groupimage}
									height={45}
									width={45}
									alt=""
									className="rounded-full align-bottom object-cover"
									wrapperclassname="rounded-full align-bottom"
								/>
								{type === "single" &&
									id !== loginResponse.data.chat_id &&
									id !== 11 &&
									id !== "posh_ai" && (
										<StatusIcon
											status={userStatus[chat?.user_id]}
											dissapearing={chat?.disappearing_duration}
										/>
									)}
							</div>
						) : (
							<SkeletonAvatar
								effect="wave"
								className="rounded-full align-bottom h-[45px] w-[45px]"
							/>
						)}
						<div className="flex flex-col justify-center items-start ml-[20px]">
							{chat ? (
								<div className="flex flex-row justify-between">
									<span className="text-body text-base font-medium whitespace-nowrap text-ellipsis overflow-hidden max-w-[200px] max-h-[70px]">{`${
										id !== "null" && id === loginResponse.data.chat_id
											? t("My Notes")
											: chat?.nickname
											? chat?.nickname
											: `${chat?.firstname} ${chat?.lastname}`
									}`}</span>
									{loginResponse.data.chat_id !== id &&
										id !== "posh_ai" &&
										id !== 11 && (
											<Button
												className="h-[25px]"
												onClick={() => {
													setInfoPanel(!infoPanel);
												}}>
												<Setting2 size={15} color="#5d6980" />
											</Button>
										)}
								</div>
							) : (
								<SkeletonText effect="wave" tag="span">
									Loading Nickname
								</SkeletonText>
							)}
							{loginResponse.data.chat_id !== id && id !== "posh_ai" && (
								<>
									{chat ? (
										<span className="w-full text-secondary text-[13px] font-medium truncate participants-text">
											{typingText
												? typingText
												: chat?.type === "group"
												? displayedNames.length > 0
													? `${displayedNames.join(", ")}`
													: t("You")
												: `${t(statusMessage)}`}
										</span>
									) : (
										<SkeletonText effect="wave" tag="span">
											Lorem ipsum dolor sit amet
										</SkeletonText>
									)}
								</>
							)}
						</div>
					</div>
					<NavRight className="md:mr-[20px]">
						<Button onClick={() => setSearchPanel(true)}>
							<MaterialIcon
								icon="search"
								weight="400"
								color="#5d6980"
								size={20}
								className="h-[20px] w-[20px] text-[22px]"
							/>
						</Button>
						{chat && chat.chat_id === "posh_ai" && (
							<div className="flex items-center gap-2.5">
								<span>Web Search</span>
								<Toggle
									tooltip="Web Search enables Posh AI to look for updated information from the internet."
									tooltipTrigger="hover"
									checked={AIWebSearch}
									onToggleChange={(e) => {
										setAIWebSearch(!e);
									}}
								/>
							</div>
						)}
						{chat &&
							chat?.chat_id != loginResponse.data.chat_id &&
							chat?.chat_id != 11 &&
							chat?.chat_id !== "posh_ai" &&
							callChatId !== chat?.chat_id &&
							callInProgress != chat?.chat_id && (
								<Button
									popupOpen=".callscreen-popup"
									onClick={() => {
										callStart("video", chat?.type);
									}}>
									<Video size="20" color="#5d6980" variant="Linear" />
								</Button>
							)}
						{chat &&
							chat?.chat_id != loginResponse.data.chat_id &&
							chat?.chat_id != 11 &&
							chat?.chat_id !== "posh_ai" &&
							callChatId !== chat?.chat_id &&
							(callInProgress == chat?.chat_id ? (
								<Button
									fill
									round
									small
									className="gap-1.5"
									popupOpen=".callscreen-popup"
									onClick={() => {
										callStart("audio", chat?.type);
									}}>
									<CallCalling size="18" color="#ffffff" variant="Linear" />{" "}
									{t("Join")}
								</Button>
							) : (
								<Button
									popupOpen=".callscreen-popup"
									onClick={() => {
										callStart("audio", chat?.type);
									}}>
									<Call size="20" color="#5d6980" variant="Linear" />
								</Button>
							))}
						{chat && callChatId === chat?.chat_id && (
							<Button
								fill
								round
								small
								// tonal
								popupOpen=".callscreen-popup"
								className="gap-1.5">
								<CallCalling size="18" color="#ffffff" variant="Linear" />{" "}
								{t("Return to call")}
							</Button>
						)}
					</NavRight>
				</Navbar>
			)}
			{id !== "null" && selectedMessages.length > 0 && (
				<Navbar slot="fixed">
					<NavLeft>
						<Button
							className="p-0 h-[40px]"
							onClick={() => f7.store.dispatch("resetSelectedMessages")}>
							<MaterialIcon
								size={24}
								icon="close"
								weight={300}
								className="text-secondary"
							/>
						</Button>
					</NavLeft>
					<div className={`flex flex-row justify-center`}>
						<div className="flex flex-col justify-center items-start ml-[20px]">
							<div className="flex flex-row justify-between">
								<span className="text-body text-base font-medium whitespace-nowrap text-ellipsis overflow-hidden max-w-[200px] max-h-[70px]">
									{t("{{count}} Selected", {
										count: selectedMessages.length,
									})}
								</span>
							</div>
						</div>
					</div>
					<NavRight className="md:mr-[20px]">
						<Button
							className="p-5 h-[40px]"
							onClick={() => {
								DeleteMessage(selectedMessages);
							}}>
							<MaterialIcon
								size={24}
								icon="delete"
								weight={300}
								className="text-secondary"
							/>
						</Button>
						{selectedMessages.filter((msg) => msg.type === "image")?.length <
							2 && (
							<Button className="p-5 h-[40px]" onClick={copyMultipleMessage}>
								<MaterialIcon
									size={24}
									icon="content_copy"
									weight={300}
									className="text-secondary"
								/>
							</Button>
						)}
						{id !== "posh_ai" && id !== 11 && (
							<Button
								className="p-5 h-[40px]"
								onClick={() => {
									// f7.store.dispatch("setSearchDisabled", true);
									setMessageContext(selectedMessages);
									setForwardPanel(true);
								}}>
								<MaterialIcon
									size={24}
									icon="redo"
									weight={300}
									className="text-secondary"
								/>
							</Button>
						)}
					</NavRight>
				</Navbar>
			)}
			{/* </KeepAlive> */}
			<Menu
				slot="fixed"
				id="chat-menu"
				theme="light"
				animation="fade"
				disableBoundariesCheck={false}>
				<Item
					hidden={hideReply}
					onClick={({ props }) => {
						f7.store.dispatch("setReply", props);
						document.body.classList.add("expanded-messagebar");
					}}>
					{t("Reply")}
				</Item>
				<Item
					hidden={hideForward}
					onClick={({ props }) => {
						setMessageContext(props);
						// f7.store.dispatch("setSearchDisabled", true);
						setForwardPanel(true);
					}}>
					{t("Forward")}
				</Item>
				<Item
					hidden={hideEdit}
					onClick={({ props }) => {
						f7.store.dispatch("setEdit", props);
						document.body.classList.add("expanded-messagebar");
					}}>
					{t("Edit")}
				</Item>
				<Item
					hidden={hideInfo}
					onClick={({ props }) => {
						setMessageContext(props);
						setMsgInfoPanel(true);
						dispatch(
							getMessageInfoService({
								message_id: Array.isArray(props) ? props[0].id : props.id,
							})
						);
					}}>
					{t("Message Info")}
				</Item>
				<Item
					hidden={hideCopy}
					onClick={({ props }) => {
						if (props.type === "text") copyMessage(props);
						else if (props.type === "image") copyImgToClipboard(props);
					}}>
					{t("Copy to clipboard")}
				</Item>
				<Item onClick={({ props }) => DeleteMessage(props)}>{t("Delete")}</Item>
				<Item
					onClick={({ props }) => {
						Array.isArray(props)
							? props.map((msg) => selectMessage(msg))
							: selectMessage(props);
					}}>
					{t("Select Message")}
				</Item>
			</Menu>
			<Panel
				slot="fixed"
				id="infoPanel"
				opened={infoPanel}
				onPanelClosed={() => setInfoPanel(false)}
				// containerEl="#chats"
				right
				reveal
				backdrop={false}
				className="rounded-none z-[1000]">
				{id !== "null" &&
					type === "single" &&
					id !== loginResponse.data.chat_id &&
					chat && (
						<ContactInfo
							chat_id={id}
							user_id={chat?.user_id}
							userMode={false}
							onConnectClick={null}
						/>
					)}

				{id !== "null" &&
					type === "group" &&
					mode === "view" &&
					!membersGroup &&
					!addMoreParticipants && (
						<GroupInfo
							chat_id={id}
							switchMode={() => setMode("edit")}
							openMembersGroup={() => setMembersGroup("view")}
							openAddMembers={() => setAddMoreParticipants("view")}
							f7router={f7router}
						/>
					)}
				{id !== "null" &&
					type === "group" &&
					mode === "edit" &&
					!membersGroup &&
					!addMoreParticipants && (
						<EditGroupInfo chat_id={id} switchMode={() => setMode("view")} />
					)}
				{id !== "null" &&
					type === "group" &&
					!addMoreParticipants &&
					membersGroup === "view" && (
						<GroupMembers
							chat_id={id}
							switchMode={() => setMembersGroup(null)}
							openAddMembers={() => setAddMoreParticipants("view")}
						/>
					)}
				{id !== "null" &&
					type === "group" &&
					addMoreParticipants === "view" && (
						<AddMoreParticipants
							chat_id={id}
							switchMode={() => setAddMoreParticipants(null)}
						/>
					)}
			</Panel>
			<Panel
				slot="fixed"
				id="forwardPanel"
				opened={forwardPanel}
				onPanelClosed={() => {
					setForwardPanel(false);
					// f7.store.dispatch("setSearchDisabled", true);
					removeMessageContext();
					f7.store.dispatch("resetSelectedMessages");
				}}
				// containerEl="#chats"
				right
				reveal
				backdrop={false}
				className="rounded-none z-[11001]">
				{id !== "null" && messageContext && (
					<MessageForward
						chat_id={id}
						hideUnread={hideUnread}
						opened={forwardPanel}
					/>
				)}
			</Panel>
			<Panel
				slot="fixed"
				id="searchPanel"
				opened={searchPanel}
				onPanelClosed={() => {
					setSearchPanel(false);
				}}
				// containerEl="#chats"
				right
				reveal
				backdrop={false}
				className="rounded-none z-[1000]">
				{id !== "null" && <SearchPanel chat_id={id} />}
			</Panel>
			<Panel
				slot="fixed"
				id="msgInfoPanel"
				opened={msgInfoPanel}
				onPanelClosed={() => {
					setMsgInfoPanel(false);
					removeMessageContext();
				}}
				// containerEl="#chats"
				right
				reveal
				backdrop={false}
				className="rounded-none z-[1000]">
				{id !== "null" && messageContext && (
					<MessageInfoPanel
						reactionsOpen={reactionsOpen}
						members={targetMembers}
						type={type}
						displayMenu={displayMenu}
						opened={msgInfoPanel}
					/>
				)}
			</Panel>
			<Popup
				slot="fixed"
				opened={selectedMessage !== null}
				tabletFullscreen
				className="lightbox-popup"
				backdrop={false}
				closeByBackdropClick={false}
				closeOnEscape
				onPopupClose={() => f7.store.dispatch("setSelectedMessageId", null)}>
				<Lightbox
					chat_id={id}
					selectedMessage={selectedMessage}
					chatType={type}
					opened={selectedMessage !== null}
					closePopup={() => setSelectedMessage(null)}
					onDeleteMessage={DeleteMessage}
					onForwardPanel={() => {
						// f7.store.dispatch("setSearchDisabled", true);
						setForwardPanel(true);
					}}
				/>
			</Popup>
			<Popup
				slot="fixed"
				tabletFullscreen
				className="live-location-popup"
				backdrop={false}
				closeByBackdropClick={false}
				closeOnEscape>
				<LiveMap />
			</Popup>
			<Popover
				slot="fixed"
				verticalPosition="top"
				arrow={false}
				backdrop
				closeByBackdropClick
				closeByOutsideClick
				closeOnEscape
				onPopoverOpened={() => setReactionsOpen(true)}
				onPopoverClosed={() => {
					setReactionsOpen(false);
					removeMessageContext();
					setMyExistingReactions([]);
				}}
				className="reaction-menu absolute md:right-0">
				<EmojiPicker
					className={`${reactionsOpen && "open"} ${
						myExistingReactions?.length > 0 ? "has-my-reactions" : ""
					}`}
					reactionsDefaultOpen={true}
					reactions={[
						"1f44d", //like
						"2764-fe0f", //heart
						"1f603", //smile
						"1f973", //excited
						"1f923", //laugh
						"1f622", //cry
						"1f621", //angry
						"1f614", //sad
						"1f64f", //thanks
						"1f44e", //dislike
					]}
					onReactionClick={handleReaction}
				/>
			</Popover>
			<Popover
				id="reaction-popover"
				arrow={false}
				backdrop
				closeByBackdropClick
				closeByOutsideClick
				closeOnEscape
				className={`reaction-popover ${
					messageContext?.sender_id !== loginResponse.data.id
						? "received-reaction"
						: "sent-reaction"
				} absolute right-0 p-2.5`}
				onPopoverClosed={() => {
					removeMessageContext();
					setReactions(null);
					setAllReactionsCount(null);
				}}>
				<Toolbar tabbar scrollable>
					<Link
						tabLink="#reactiontab-0"
						tabLinkActive
						className="text-[16px] text-body w-[57px]">
						All {allReactionsCount || ""}
					</Link>
					{reactions?.map((reaction, index) => {
						return (
							<Link
								key={index}
								tabLink={`#reactiontab-${index + 1}`}
								tabLinkActive={index === 1}
								className="flex-row justify-around w-[57px]">
								<Emoji unified={REACTIONS[reaction]} size={16} />
								{messageContext &&
									userReactions &&
									Object.keys(userReactions)?.length > 0 && (
										<span className="text-[16px] text-body ml-1">
											{userReactions?.reactions[reaction]?.length || ""}
										</span>
									)}
							</Link>
						);
					})}
				</Toolbar>

				<Tabs>
					<Tab id="reactiontab-0" tabActive className="h-[206px] overflow-auto">
						<List
							className={`${
								!reactions && "skeleton-text skeleton-effect-wave"
							}`}>
							{!reactions && (
								<ListItem
									title="Placeholder account name"
									footer="placeholder footer"
									media={userimage}></ListItem>
							)}
							{messageContext &&
								reactions &&
								userReactions &&
								Object.keys(userReactions).length > 0 &&
								reactions?.map((reaction, index) => {
									return userReactions?.reactions[reaction]?.map(
										(user, uindex) => {
											return (
												<ListItem
													key={index + uindex}
													title={user.firstname}
													// {...(parseInt(user.id) === loginResponse.data.id
													// 	? {
													// 			footer: "Click to remove",
													// 			className: "cursor-pointer",
													// 			onClick: () => {
													// 				f7.popover.close();
													// 				setTimeout(() => {
													// 					removeReaction(reaction);
													// 				}, 200);
													// 			},
													// 	  }
													// 	: {})}
													media={user.profile_image}>
													<Emoji
														slot="after"
														unified={REACTIONS[reaction]}
														size={28}
													/>
												</ListItem>
											);
										}
									);
								})}
						</List>
					</Tab>
					{reactions?.map((reaction, index) => {
						return (
							<Tab
								id={`reactiontab-${index + 1}`}
								key={index}
								className="h-[206px] overflow-auto">
								<List>
									{userReactions &&
										Object.keys(userReactions).length > 0 &&
										userReactions?.reactions[reaction]?.map((user, uindex) => {
											return (
												<ListItem
													key={index + uindex}
													title={user.firstname}
													// {...(parseInt(user.id) === loginResponse.data.id
													// 	? {
													// 			footer: "Click to remove",
													// 			className: "cursor-pointer",
													// 			onClick: () => {
													// 				f7.popover.close();
													// 				setTimeout(() => {
													// 					removeReaction(reaction);
													// 				}, 200);
													// 			},
													// 	  }
													// 	: {})}
													media={user.profile_image}>
													<Emoji
														slot="after"
														unified={REACTIONS[reaction]}
														size={28}
													/>
												</ListItem>
											);
										})}
								</List>
							</Tab>
						);
					})}
				</Tabs>
			</Popover>
			<PageContent
				ref={pageContent}
				messagesContent
				className={`overflow-x-hidden ${
					!messageCount ? "no-toolbar-padding" : ""
				}`}>
				{showMessagebar && (
					<Messagebar
						id={id}
						toggleAudioInput={toggleAudioInput}
						hideUnread={hideUnread}
					/>
				)}
				{showAudiobar && (
					<Audiobar
						id={id}
						toggleAudioInput={toggleAudioInput}
						hideUnread={hideUnread}
					/>
				)}
				{!showMessagebar && messagebarReason && (
					<div className="toolbar messagebar md:pl-[12px] pl-[6px] md:pr-[24px] pr-[6px] py-[15px] md:gap-[20px] flex items-center justify-center">
						<span className="text-[#5D6980] text-base text-center">
							{members ? messagebarReason : t("Loading Chat...")}
						</span>
					</div>
				)}
				{id === "null" && (
					<div className="w-full h-full flex justify-center items-center">
						<div className="h-[347px] w-[321px] bg-white/40 rounded-[20px] flex flex-col justify-center items-center p-[30px] gap-y-2.5">
							<img src={conversation_img} className="w-full" />
							<span className="text-xl text-secondary font-bold">
								{f7.name} {f7.device.electron ? "desktop" : "web"}
							</span>
							<span className="text-base text-center text-secondary w-[261px]">
								{t(
									"Welcome to KT Messenger! Where every message counts. Start chatting, sharing, and connecting with your friends and family like never before."
								)}
							</span>
						</div>
					</div>
				)}
				{id !== "null" && loadingState === "loaded" && (
					<Messages
						ref={messagesRef}
						scrollMessages={false}
						scrollMessagesOnEdge={false}>
						{messages &&
							messages.map((message, index) => {
								if (message.type === "dateHeader") {
									return (
										<DateHeader
											key={`message${index}`}
											id={
												message.content.toLowerCase() !== "unread"
													? message.content.replace(/[\s,]+/g, "_")
													: "unread"
											}
											date={
												message.content.toLowerCase() !== "unread"
													? message.content
													: t(`{{count}} Unread Messages`, {
															count: unreadCount,
													  })
											}
											background={
												message.content.toLowerCase() === "unread"
													? true
													: false
											}
											firstHeader={index === 0}
											// loadMoreMessages={loadMoreMessages}
										/>
									);
								} else {
									return (
										<RenderMessage
											key={`message${index}`}
											message={message.content}
											reactions={message.content?.reactions || null}
											reactionsOpen={reactionsOpen}
											members={members}
											type={type}
											displayMenu={displayMenu}
											infoOpened={msgInfoPanel}
											setSelectedMessage={setSelectedMessage}
											conditionalScrollToBottom={conditionalScrollToBottom}
											callStart={callStart}
											selected={
												selectedMessages.find((msg) =>
													Array.isArray(message.content)
														? msg.id === message.content[0].id
														: msg.id === message.content.id
												)
													? true
													: false
											}
											setMyExistingReactions={setMyExistingReactions}
										/>
									);
								}
							})}
					</Messages>
				)}
				{/* 	
				{id !== "null" && loadingState === "empty" && (
					<div className="w-full h-full flex justify-center items-center">
						<div
							className="h-[347px] w-[321px] bg-white/50 rounded-[10px] flex flex-col justify-center items-center p-[20px] gap-y-2.5 cursor-pointer"
							onClick={() => f7.emit("sendhi", id)}>
							<span className="text-xl text-secondary font-bold">								
								{t("No messages here yet")}
							</span>
							<span className="text-base text-center text-secondary">
								
								{t("Send a message or tap here")}
								<br />								
							</span>
							<img src={hand} className="w-[125px]" />
						</div>
					</div>
				)}
				*/}
				{id !== "null" && loadingState === "loading" && (
					<div className="h-full flex justify-center items-center">
						<div className="w-full h-full flex flex-col p-[51px] gap-[40px]">
							<ChatShimmer width="360px" height="91px" />
							<ChatShimmer owner={true} width="427px" height="77px" />
							<ChatShimmer width="333px" height="91px" />
							<ChatShimmer owner={true} width="427px" height="54px" />
							<ChatShimmer width="333px" height="91px" />
							<ChatShimmer owner={true} width="427px" height="130px" />
						</div>
					</div>
				)}
			</PageContent>
			<Fab
				position="right-bottom"
				slot="fixed"
				className={`icon ${showFab ? "show" : ""}`}
				onClick={() => f7.emit("scrollToBottom")}>
				<MaterialIcon
					icon="expand_more"
					className="text-secondary text-[24.7px]"
				/>
				{Object.keys(unseenMessages).includes(id) &&
				unseenMessages[id]?.length > 0 ? (
					<Badge color="blue">{unseenMessages[id]?.length}</Badge>
				) : null}
			</Fab>
		</Page>
	);
});

export default SingleChatPage;
