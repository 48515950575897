import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { post } from "../../../axios/axiosClient";
import { encrypt } from "../../../utils/crypto";
import { db } from "../../../js/db";
import Dexie from "dexie";

const initialState = {
	pendingActionsResponse: {},
	isLoading: false,
	error: false,
};

export const pendingActionsSlice = createSlice({
	name: "pendingActions",
	initialState,
	reducers: {
		savePendingActionsResponse: (state, action) => {
			state.pendingRequestsResponse = action.payload;
		},
		resetPendingActionsResponse: (state) => {
			state.pendingActionsResponse = {};
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(pendingActionsService.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(pendingActionsService.fulfilled, (state, action) => {
				state.isLoading = false;
				if (!action?.payload?.data) return;
				state.pendingActionsResponse = action.payload;
				// storing data to db
				
			})
			.addCase(pendingActionsService.rejected, (state, action) => {
				state.isLoading = false;
				state.error = action.error.message;
			});
	},
});

const pendingActionsService = createAsyncThunk(
	"user/pendingActions",
	async (values) => {
		if (!values) return;

		return post("/user/get_pending_actions", {
			type: "application/x-www-form-urlencoded",
			data: { encodedParams: await encrypt(JSON.stringify(values)) },
		});
	}
);

export const { savePendingActionsResponse, resetPendingActionsResponse } =
	pendingActionsSlice.actions;
export { pendingActionsService };

export default pendingActionsSlice.reducer;
