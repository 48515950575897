import React, { useState, useEffect, useCallback, useRef } from "react";
import { useUpdateEffect } from "react-use";

import { f7, f7ready, App, View } from "framework7-react";
import { getDevice } from "framework7/lite-bundle";

import { Provider } from "react-redux";
import { store } from "../redux/store";

import routes from "../js/routes";
import f7store from "../js/store";
import {
	addPronoun,
	getCookieAsync,
	isJson,
	removeMD,
} from "../utils/functions";
import { AUTHTOKEN_COOKIE } from "../constants";
import "../i18n";
import { v4 as uuidv4 } from "uuid";

import { socketInit, SocketContext } from "../socket";
import { bindSystemEvents, unbindSystemEvents } from "../socket/initEvents";
import { bindAppEvents, unbindAppEvents } from "../socket/appEvents";
import { clearcache, updateApp } from "../utils/updateApp";
import { db } from "../js/db";

import { pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
	"pdfjs-dist/build/pdf.worker.min.mjs",
	import.meta.url
).toString();

import notification from "../assets/sounds/notification.mp3";
import { ENDPOINTS } from "../constants/socket";

import { ErrorBoundary } from "react-error-boundary";
import ErrorComponent from "../pages/error";
import Titlebar from "./navs/Titlebar";

import { AliveScope } from "react-activation";
import Splash from "./splash";
import InactivityProvider from "./inactivityProvider";

import { GoogleOAuthProvider } from "@react-oauth/google";
import urlJoin from "url-join";

import analytics from "../js/firebase/analytics";
import { logEvent } from "@firebase/analytics";
import { post } from "../axios/axiosClient";

const MyApp = () => {
	const [socket, setSocket] = useState(null);
	const device = getDevice();
	const [userId, setUserId] = useState(null);
	const [notificationAudio] = useState(new Audio(notification));
	const isNotificationPlaying = useRef(false);
	const [pageVisible, setPageVisible] = useState(true);
	const pushNotification = useRef(null);

	const checkUpdate = () => {
		updateApp()
			.then((update) => {
				if (update) {
					f7.dialog.progress(
						`Updating ${f7params.name} to ${update.currentVersion}`
					);

					setTimeout(() => {
						try {
							localStorage.setItem("appVersion", update.currentVersion);
							update.isBroken = true;
							update.isBroken
								? !db.isOpen()
									? db
											.open()
											.then(() => {
												db.delete().then(() => {
													clearcache();
												});
											})
											.catch(function (err) {
												console.error(
													"Failed to open db: " + (err.stack || err)
												);
												clearcache();
											})
									: db.delete().then(() => {
											clearcache();
									  })
								: clearcache();
						} catch (err) {
							console.error(err);
							clearcache();
						}
					}, 1000);
				} else {
				}
			})
			.catch((err) => {});
	};

	// Framework7 Parameters
	const f7params = {
		name: "KT Messenger", // App name
		theme: "md", // "ios" or "md" or "auto"
		colors: {
			primary: "#0E99FE",
		},

		// App routes
		routes: routes,
		//store
		store: f7store,

		// Register service worker if PWA (only on production build)
		serviceWorker:
			process.env.NODE_ENV === "production"
				? {
						path: "/service-worker.js",
				  }
				: {},
		input: {
			scrollIntoViewOnFocus: !device.desktop,
			scrollIntoViewCentered: !device.desktop,
		},
		toast: {
			closeTimeout: 3000,
			horizontalPosition: "center",
			destroyOnClose: true,
		},
		popover: {
			backdrop: false,
			arrow: false,
		},
		touch: {
			touchRippleElements:
				".ripple, .link, li:not(.recent-chat-item) .item-link, .list-button, .links-list a, .button, button, .input-clear-button, .dialog-button, .tab-link, .item-radio, .item-checkbox, .actions-button, .searchbar-disable-button, .fab a, .checkbox, .radio, .data-table .sortable-cell:not(.input-cell), .notification-close-button, .stepper-button, .stepper-button-minus, .stepper-button-plus, .menu-item-content, .list.accordion-list .accordion-item-toggle",
		},
		on: {
			init: async () => {
				logEvent(analytics, "app_init");

				f7.store.dispatch("setAISessionId", uuidv4());

				addEventListener("visibilitychange", () =>
					setPageVisible(document.visibilityState === "visible")
				);

				checkUpdate();

				if (!db.isOpen()) {
					db.open()
						.then(() => {})
						.catch(function (err) {
							console.error("Failed to open db: " + (err.stack || err));
						});
				}
			},
			pageBeforeOut: () => {
				f7.sheet.close();
				f7.popup.close();
				f7.panel.close();
				f7.popover.close();
				f7.store.dispatch("resetSelectedMessages");
			},
			online: async () => {
				f7.toast
					.create({
						text: "Connected to the inetrnet",
						horizontalPosition: "center",
					})
					.open();

				const token = await getCookieAsync(AUTHTOKEN_COOKIE);
				token &&
					post("/user/get_pending_actions", {
						req_id: "get_pending_actions",
					})
						.then((res) => {
							console.log(res);
						})
						.catch((err) => console.error(err));

				const interval = setInterval(() => {
					if (socket) {
						db.messages
							.where("is_read")
							.equals(-1)
							.toArray()
							.then((messages) => {
								messages.forEach((message) => {
									socket.emit(
										ENDPOINTS.SEND_MESSAGE,
										JSON.stringify({
											message: message.type === "text" ? message.message : "",
											chat_id: message.chat_id,
											receiver_id: 0,
											identifier: message.identifier,
											group_id: message.identifier,
											...(message.type !== "text" && {
												is_group: message.is_group,
												mType: message.type,
												file_type: message.file_type,
												file_size: message.file_size,
												caption: message.caption,
												file_id: message.file_id,
												audio_url: message.file_url,
												thumbnail: message.thumbnail,
											}),
										}),
										async (response) => {
											db.messages
												.where({
													identifier: response.identifier,
												})
												.modify((value, ref) => {
													ref.value = {
														...response,
														sender_id: userId,
														unix_time:
															new Date(response.updated_at).getTime() / 1000,
													};
												});
										}
									);
								});
							});

						clearInterval(interval);
					}
				}, 5000);
			},
			offline: () => {
				f7.toast
					.create({
						text: "You're offline. Please check your network connection",
						horizontalPosition: "center",
						closeButtonText: "Close",
						closeButton: true,
						closeTimeout: 0,
					})
					.open();
			},
		},
	};

	f7ready(async () => {
		// set F7 global API config here
	});

	useEffect(() => {
		(async () => {
			const token = await getCookieAsync(AUTHTOKEN_COOKIE);
			socket && socket.disconnect();
			socketInit(token).then((_socket) => {
				setSocket(_socket);
			});
		})();

		f7?.on("loginSuccess", async (userid) => {
			setUserId(userid);
			socket && socket.disconnect();
			const token = await getCookieAsync(AUTHTOKEN_COOKIE);
			socketInit(token).then((_socket) => {
				setSocket(_socket);
			});
		});

		f7?.on("notification", (data) => {
			if (!isNotificationPlaying.current && document.hidden) {
				playNotification(data);
			}
		});
		f7?.on("reactionNotification", (data) => {
			if (!isNotificationPlaying.current && document.hidden) {
				playNotification(data);
			}
		});

		return () => {
			f7?.off("loginSuccess");
			f7?.off("notification");
		};
	}, []);

	// useEffect(() => {
	// 	if (pushNotification.current) {
	// 		pushNotification.current.close();
	// 	}
	// }, [pageVisible]);

	const playNotification = useCallback((data) => {
		isNotificationPlaying.current = true;

		try {
			notificationAudio.onended = () => {
				isNotificationPlaying.current = false;
			};

			notificationAudio.onerror = () => {
				isNotificationPlaying.current = false;
			};

			let title, body, message;

			if (data.id) {
				if (data?.message) {
					if (isJson(data?.message)) {
						const subtitleJson = JSON.parse(data?.message);
						if (data.type === "mms") {
							message = t("shared an MMS");
						}
						if (data.type === "member") {
							const memberName =
								parseInt(localStorage.getItem("uid")) ===
								parseInt(subtitleJson.id)
									? t("You")
									: subtitleJson.firstname;
							if (subtitleJson.type === "create") {
								message = `${data.sender_name} created this group`;
							} else if (subtitleJson.type === "remove") {
								message = `${data.sender_name} removed ${memberName}`;
							} else if (subtitleJson.type === "add") {
								message = `${data.sender_name} added ${memberName}`;
							} else if (subtitleJson.type === "left") {
								message = `${data.sender_name} left the group`;
							}
						} else if (
							subtitleJson?.disappear === "0" ||
							subtitleJson?.disappear === 0
						) {
							message =
								subtitleJson.firstname +
								" " +
								t("turned off disappearing messages");
						} else if (
							subtitleJson?.disappear === "1" ||
							subtitleJson?.disappear === 1
						) {
							const durationHumanized = moment
								.duration(subtitleJson.duration, "minutes")
								.humanize();
							const commonMessage = t(
								"turned on disappearing messages. New messages will disappear from this chat"
							);
							const dynamicPart = `${commonMessage} ${durationHumanized} ${t(
								"after they're sent, except when kept"
							)}`;

							message = `${subtitleJson.firstname} ${dynamicPart}`;
						}
					} else {
						message = removeMD(
							data.type === "text"
								? data.message.replace(
										/@\[([^\]]+)\]\((\d+)\)/g,
										(match, name, id) => `@${name}`
								  )
								: data.type === "location"
								? "Shared a location"
								: data.message?.length > 0
								? data.message
								: `Shared ${addPronoun(data.type)}`
						);
					}
				} else {
					message = removeMD(
						data.type === "text"
							? data.message.replace(
									/@\[([^\]]+)\]\((\d+)\)/g,
									(match, name, id) => `@${name}`
							  )
							: data.type === "location"
							? "Shared a location"
							: data.message?.length > 0
							? data.message
							: `Shared ${addPronoun(data.type)}`
					);
				}
				title =
					data.group_name && data.group_name !== "null"
						? data.group_name
						: data.sender_name;
				body =
					data.group_name && data.group_name !== "null"
						? `${data.sender_name}: ${message}`
						: message;
				if (data.group_name && data.group_name !== "null") {
					const uid = parseInt(localStorage.getItem("uid"));
					const mentionRegex = new RegExp(`@\\[([^\\]]+)\\]\\(${uid}\\)`, "i");
					if (data?.message && mentionRegex.test(data.message)) {
						if (localStorage.getItem("group-mention-sound") !== "silence") {
							notificationAudio.play();
						}
					} else if (
						localStorage.getItem("group-notification-sound") !== "silence"
					) {
						notificationAudio.play();
					}
				}
			} else if (data.reaction_type && data.type === "add") {
				title = "New Reaction";
				body = `${data.name} reacted to your message`;
				if (localStorage.getItem("reaction-notification") !== "silence") {
					notificationAudio.play();
				}
			} else {
				return;
			}

			pushNotification.current = new Notification(title, {
				body: body,
				icon: urlJoin(window.location.href, "/icons/128x128.png"),
				silent: true,
			});

			pushNotification.current.onclick = (e) => {
				e.preventDefault();
				window.focus();
				pushNotification.current.close();
			};
		} catch (ex) {
			console.error(ex);
			isNotificationPlaying.current = false;
		}
	}, []);

	useUpdateEffect(() => {
		//init events
		if (socket) {
			bindSystemEvents(socket);
			bindAppEvents(socket);
		}

		return () => {
			if (socket) {
				unbindSystemEvents(socket);
				unbindAppEvents(socket);
			}
		};
	}, [socket]);

	useEffect(() => {
		socket?.emit(
			ENDPOINTS.CHANGE_USER_STATUS,
			JSON.stringify({ online: pageVisible, last_seen: Date.now() })
		);
	}, [pageVisible, socket]);

	useUpdateEffect(() => {
		const textArea = document.getElementById("textInput");
		if (pageVisible && !f7store.state.callChatId && !textArea?.value) {
			checkUpdate();
		}
	}, [pageVisible]);

	return (
		<ErrorBoundary FallbackComponent={ErrorComponent}>
			<InactivityProvider store={f7store}>
				<GoogleOAuthProvider clientId="734898322273-7mn161l421h29ddbb3s4cgtvgef2gp95.apps.googleusercontent.com">
					<Provider store={store}>
						<SocketContext.Provider value={socket}>
							<AliveScope>
								<Splash>
									<App {...f7params}>
										{/* <Titlebar /> */}
										<View
											main
											className="safe-areas"
											url="/"
											masterDetailBreakpoint={775}
											reloadDetail
											// browserHistory={!device.cordova}
											// browserHistorySeparator=""
											// browserHistoryAnimateOnLoad
										/>
									</App>
								</Splash>
							</AliveScope>
						</SocketContext.Provider>
					</Provider>
				</GoogleOAuthProvider>
			</InactivityProvider>
		</ErrorBoundary>
	);
};
export default MyApp;
