import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import {
	persistReducer,
	persistStore,
	FLUSH,
	REHYDRATE,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
} from "redux-persist";

//import slices
import locationSlice from "./features/locationSlice/location";
import loginSlice from "./features/authSlice/login";
import logoutSlice from "./features/authSlice/logout";
import signupSlice from "./features/authSlice/signup";
import contactSlice from "./features/userSlice/contacts";
import findFriendsSlice from "./features/userSlice/findFriends";
import conversationSlice from "./features/chatSlice/conversation";
import createGroupSlice from "./features/chatSlice/createGroup";
import chatMessageSlice from "./features/chatSlice/chatMessages";
import editGroupSlice from "./features/chatSlice/editGroup";
import profileSlice from "./features/userSlice/profile";
import groupDetailSlice from "./features/chatSlice/groupDetail";
import pendingRequestsSlice from "./features/userSlice/pendingRequests";
import pendingActionsSlice from "./features/userSlice/pendingActions";
import acceptRejectRequestSlice from "./features/userSlice/acceptRejectRequest";
import uploadFileSlice from "./features/chatSlice/uploadFile";
import pwdResetTokenSlice from "./features/passwordResetSlice/requestpwdToken";
import profileUpdateSlice from "./features/userSlice/profileUpdate";
import reactionDetailSlice from "./features/chatSlice/reactionDetail";
import metaSlice from "./features/metaSlice";
import stories from "./features/storiesSlice/index";
import currentStories from "./features/storiesSlice/currentStories";
import userStory from "./features/storiesSlice/userStory";
import getMessageInfoSlice from "./features/chatSlice/messageInfo";
import mentionsSlice from "./features/userSlice/mentions";
import LoggedDeviceSlice from "./features/userSlice/devices";
import FeedBackSlice from "./features/userSlice/feedBack";
import DeleteAccountSlice from "./features/userSlice/deleteAccount"
import deleteContactSlice from "./features/userSlice/deleteContact";


const reducers = combineReducers({
	locationSlice,
	loginSlice,
	logoutSlice,
	signupSlice,
	contactSlice,
	findFriendsSlice,
	conversationSlice,
	createGroupSlice,
	chatMessageSlice,
	editGroupSlice,
	profileSlice,
	groupDetailSlice,
	pendingRequestsSlice,
	pendingActionsSlice,
	mentionsSlice,
	acceptRejectRequestSlice,
	uploadFileSlice,
	pwdResetTokenSlice,
	profileUpdateSlice,
	reactionDetailSlice,
	metaSlice,
	getMessageInfoSlice,
	stories,
	currentStories,
	userStory,
	LoggedDeviceSlice,
	FeedBackSlice,
	DeleteAccountSlice,
	deleteContactSlice,
});

const persistConfig = {
	key: "root",
	version: 1,
	storage,
	whitelist: [
		"loginSlice",
		"conversationSlice",
		"contactSlice",
		"profileSlice",
		"signupSlice",
		"pwdResetTokenSlice",
		"pwdVerifyTokenSlice",
		"pendingRequestsSlice",
		"mentionsSlice",
		"stories",
	],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			},
		}),
});

let persistor = persistStore(store);

export { store, persistor };
