import React, { useState } from "react";
import { post } from "../axios/axiosClient";
import Dexie from "dexie";
import { db } from "../js/db";
import { encrypt } from "../utils/crypto";
import { STATUS_CODE } from "../constants";

const useChatDownloader = () => {
	const [downloading, setDownloading] = useState(false);
	const [progress, setProgress] = useState(0);
	const [saving, setSaving] = useState(false);

	const downloadChat = async (values) => {
		values = values || {};
		setDownloading(true);
		setProgress(0);

		post("/chat/get_latest_messages", {
			data: { encodedParams: await encrypt(JSON.stringify(values)) },
			req_id: "download_chat",
			onDownloadProgress: (progressEvent) => {
				if (progressEvent.total && progressEvent.loaded) {
					const percentCompleted = Math.round(
						(progressEvent.loaded * 100) / progressEvent.total
					);
					setProgress(percentCompleted);
					if (percentCompleted === 100) {
						setProgress(0);
					}
				} else {
					setProgress(0);
				}
			},
		})
			.then((res) => {
				setSaving(true);
				console.log(res);
				if (res.code === STATUS_CODE.SUCCESS && res.data.length > 0) {
					db.messages
						.bulkPut(res.data)
						.then((lastKey) => {
							setSaving(false);
							setDownloading(false);
						})
						.catch(Dexie.BulkError, (err) => {
							console.error(err.message);
							setSaving(false);
							setDownloading(false);
						});
				}
			})
			.catch((err) => {
				console.error(err.message);
				setProgress(0);
				setDownloading(false);
			});
	};

	return { downloading, progress, saving, downloadChat };
};

export default useChatDownloader;
